import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import React from "react";
import user1 from "../../../assets/images/testimonial_user1.png";
import user2 from "../../../assets/images/testimonial_user2.png";
import user3 from "../../../assets/images/testimonial_user3.png";
import user4 from "../../../assets/images/avtar_testimonial.png";

const Main = () => {
  const testimonial_slider = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    smartSpeed: 1500,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  return (
    <>
      <section className="row_am testimonial_section">
        <div className="container">
          <div
            className="section_title"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="300"
          >
            <h2 className="sub-title-mb">
              <span>Comentarios de</span>
            </h2>
            <h2 className="sub-title-mb">
              <pr /> nuestros usuarios
            </h2>
          </div>
          <div
            className="testimonial_block"
            data-aos="fade-in"
            data-aos-duration="1500"
          >
            <OwlCarousel
              id="testimonial_slider"
              {...testimonial_slider}
              className="owl-carousel owl-theme owl-loaded owl-drag"
            >
              <div className="item">
                <div className="testimonial_slide_box">
                  <div className="rating">
                    <span>
                      <i className="icofont-star"></i>
                    </span>
                    <span>
                      <i className="icofont-star"></i>
                    </span>
                    <span>
                      <i className="icofont-star"></i>
                    </span>
                    <span>
                      <i className="icofont-star"></i>
                    </span>
                    <span>
                      <i className="icofont-star"></i>
                    </span>
                  </div>
                  <p className="review">
                    “En cuanto a lo que dispone para ofrecer a los usuarios es
                    muy buena y rápida cómoda y segura. Estoy seguro que aún la
                    seguirán mejorando. Felicitaciones por su trabajo...”
                  </p>
                  <div className="testimonial_img">
                    <img src={user1} alt="images" />
                  </div>
                  <h3>Jose Luis Rodriguez</h3>
                </div>
              </div>
              <div className="item">
                <div className="testimonial_slide_box">
                  <div className="rating">
                    <span>
                      <i className="icofont-star"></i>
                    </span>
                    <span>
                      <i className="icofont-star"></i>
                    </span>
                    <span>
                      <i className="icofont-star"></i>
                    </span>
                    <span>
                      <i className="icofont-star"></i>
                    </span>
                    <span>
                      <i className="icofont-star"></i>
                    </span>
                  </div>
                  <p className="review">
                    “Excelente APP para pagar los servicios, muy intuitiva a la
                    hora de usarlo y procesa muy rápido los pagos.”
                  </p>
                  <div className="testimonial_img">
                    <img src={user3} alt="images" />
                  </div>
                  <h3>José Mota</h3>
                </div>
              </div>
              <div className="item">
                <div className="testimonial_slide_box">
                  <div className="rating">
                    <span>
                      <i className="icofont-star"></i>
                    </span>
                    <span>
                      <i className="icofont-star"></i>
                    </span>
                    <span>
                      <i className="icofont-star"></i>
                    </span>
                    <span>
                      <i className="icofont-star"></i>
                    </span>
                    <span>
                      <i className="icofont-star"></i>
                    </span>
                  </div>
                  <p className="review">
                    “Excelente app!! Me encanta la comodidad sobre todo cuando
                    ando con los niñitos. No me parece caro la comisión además
                    que no se me pierde el ticket en la cartera 😄 también es
                    una maravilla para pagar la facturación de mi tfno digitel,
                    excelente interfaz.”
                  </p>
                  <div className="testimonial_img">
                    <img src={user2} alt="images" />
                  </div>
                  <h3>Avimar Chacon</h3>
                </div>
              </div>
            </OwlCarousel>

            <div className="total_review">
              <div className="rating">
                <span>
                  <i className="icofont-star"></i>
                </span>
                <span>
                  <i className="icofont-star"></i>
                </span>
                <span>
                  <i className="icofont-star"></i>
                </span>
                <span>
                  <i className="icofont-star"></i>
                </span>
                <span>
                  <i className="icofont-star"></i>
                </span>
                <p>4.5 / 5.0</p>
              </div>
              {/* <Link to="/review">
                TOTAL USER REVIEWS <i className="icofont-arrow-right"></i>
              </Link> */}
            </div>

            <div className="avtar_faces">
              <img src={user4} alt="image" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;
