import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Bredcrumb from "../../Bredcrumb/Main";
import PD_1 from "../../../assets/images/pd_1.jpg";
import PD_2 from "../../../assets/images/pd_2.jpg";
import PD_3 from "../../../assets/images/pd_3.jpg";
import BGImg from "../../../assets/images/bread_crumb_bg.svg";
import BGImg1 from "../../../assets/images/bread_crumb_bg_one.png";
import BGImg2 from "../../../assets/images/bread_crumb_bg_two.png";

const Main = ({ brdcum }) => {
  const [faqCategory, setFaqCategory] = useState([]);
  const [activeFaq, setActiveFaq] = useState({});
  useEffect(() => {
    // fetch(`${process.env.REACT_APP_URL_API}/faq`, { method: "POST" })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     setFaqCategory(data.faq);
    //   });

    const data = {
      faq: [
        {
          category: "App",
          is_active: true,
          _id: "6477868b99f0f32c7a1ac6ed",
          display_name: "Paso 1.",
          questions: [
            {
              answer:
                "- Iniciar session en la App de  PagoDirecto App y luego  tocar sobre el nombre de usuario.",
              question:
                "Iniciar session en la App de  PagoDirecto App y luego  tocar sobre el nombre de usuario",
            },
          ],
          created_at: "2023-07-10T09:00:00.000Z",
        },
        {
          category: "Apps",
          is_active: true,
          _id: "6477868b99f0f32c7a1ac6ss",
          display_name: "Paso 2.",
          questions: [
            {
              answer:
                "- Iniciar session en la App de  PagoDirecto App y luego  tocar sobre el nombre de usuario.",
              question: "Tap [Legal] - [Eliminar cuenta].",
            },
          ],
          created_at: "2023-07-10T09:00:00.000Z",
        },
        {
          category: "Wallet",
          is_active: true,
          _id: "6477868b99f0f32c7a1ac6es",
          display_name: "Paso 2.",
          questions: [
            {
              answer:
                "Es una billetera virtual que te permitirá tener fondos dentro de la app y realizar pagos en todas las funciones y servicios que estén disponibles.",
              question: "¿Qué es?",
            },
            {
              answer:
                "Puedes depositar fondos desde distintos métodos: \n</br>- Pago móvil.\n</br>- Tarjetas Nacionales.\n</br> - Tarjetas Internacionales.\n</br> - Transferencias bancarias",
              question: "¿Cómo puedo depositar dinero en mi wallet?",
            },
          ],
          created_at: "2023-07-10T09:00:00.000Z",
        },
      ],
    };
    setFaqCategory(data.faq);
  }, []);

  return (
    <>
      {brdcum.b1 && (
        <Bredcrumb
          no={1}
          title="¿Cómo deshabilitar mi cuenta de PagoDirecto?"
          paragraph=" "
          tag=""
          bgimg={BGImg}
        />
      )}

      <section className="row_am faq_section">
        <div className="container">
          <div className="faq_panel">
            <div className="card my-5"  data-aos-duration="1500">
              <div
                className="card-header"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <h2
                  className="mb-0 btn  "
                  style={{ textAlign: "left", alignSelf: "flex-start" }}
                >
                  Paso 1. Iniciar sesión en la App de PagoDirecto App y  luego  tocar sobre el nombre de usuario
                </h2>
                <img
                  src={PD_1}
                  alt="faq1"
                  className="mt-5"
                  style={{ height: "600px", alignSelf: "center" }}
                />
              </div>
            </div>
            <div className="card my-5"  data-aos-duration="1500">
              <div
                className="card-header"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <h2
                  className="mb-0 btn  "
                  style={{ textAlign: "left", alignSelf: "flex-start" }}
                >
                  Paso 2. Ir a la sección [Legal] y luego la opción [Eliminar
                  cuenta].
                </h2>
                <img
                  src={PD_2}
                  alt="faq2"
                  className="mt-5"
                  style={{ height: "600px", alignSelf: "center" }}
                />
              </div>
            </div>
            <div className="card my-5"  data-aos-duration="1500">
              <div
                className="card-header"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <h2
                  className="mb-0 btn  "
                  style={{ textAlign: "left", alignSelf: "flex-start" }}
                >
                  Paso 3. Leer el mensaje de la ventana emergente y luego
                  presionar el botón [Eliminar cuenta ]
                </h2>
                <img
                  src={PD_3}
                  alt="faq3"
                  className="mt-5"
                  style={{ height: "600px", alignSelf: "center" }}
                />
              </div>
            </div>
          </div>

          <div style={{ height: "500px" }}></div>
        </div>
      </section>
    </>
  );
};

export default Main;
