import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
// import BGImg from "../../../assets/images/bread_crumb_bg.png";
// import BGImg1 from "../../../assets/images/bread_crumb_bg_one.png";
// import BGImg2 from "../../../assets/images/bread_crumb_bg_two.png";

const Main = ({ brdcum, bgimg }) => {
  const [parkingLots, setParkingLots] = useState([]);

  const homeParkings = [
    "C.C.C.T",
    "C.C. San Ignacio",
    "Cerro Verde",
    "C.C. Lider",
    "C.C. Millennium",
    "C.C. El Recreo",
    "Tolon Fashion Mall",
    "Eurobuilding"
  ];

  // GET fetch to get all parking lots with the url `${process.env.REACT_APP_URL_API}/commerces/list`

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL_API}/commerces/list`)
      .then((res) => res.json())
      .then((data) => {
        // get the first 30 parking lots of the array
        const commerces = data.commerces.filter((parkingLot, index) =>
          homeParkings.includes(parkingLot.commerce_name)
        );
        // console.log(commerces);
        // data = data.commerces;
        setParkingLots(commerces);
      });
  }, []);

  return (
    <>
      <div id="parkinglot" className="container">
        <div className="ui_text">
          <div
            className="section_title"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="100"
          >
            <h2
              style={{ margin: "100px 0 100px ", }}
              className="sub-title-mb"
            >
              <span>¿Dónde estamos?</span>
            </h2>
          </div>
        </div>
      </div>

      <section
        className="row_am latest_story blog_list_story"
        id="blog"
        style={{ marginTop: "-5rem" }}
      >
        <div className="container">
          <div className="row">
            {parkingLots.map((parkingLot) => {
              return (
                <div className="col-md-3" key={parkingLot.commerce_id}>
                  <div
                    className="story_box"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    <div className="story_img">
                      <img
                        src={`${process.env.REACT_APP_URL_IMG}/${parkingLot.commerce_id}`}
                        alt="imagse"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "assets/images/story01.png";
                        }}
                        // style={{ width: "100%", height: "250px" }}
                      />
                      {/* <span>45 min ago</span> */}
                    </div>
                    <div className="story_text" style={{ textAlign: "center" }}>
                      <h3 style={{ color: "black", fontSize: "25px" }}>
                        {parkingLot.commerce_name}
                      </h3>
                      <p
                        style={{
                          fontWeight: "700",
                          color: "#8A8A8A",
                          marginTop: "-15px",
                        }}
                      >
                        {parkingLot.commerce_category}
                      </p>

                      {parkingLot?.parking_mode?.parking?.name && (
                        <>
                          <p>
                            {parkingLot?.parking_mode?.parking?.name}{" "}
                            <span style={{ fontWeight: "bold" }}>
                              Bs.{" "}
                              {parkingLot?.parking_mode?.parking?.amount.toFixed(
                                2
                              )}
                            </span>
                          </p>
                          <p style={{ marginTop: "-20px" }}>
                            Servicio PagoDirecto{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {parkingLot?.parking_mode?.pd_fee?.amount.toFixed(
                                2
                              )}
                            </span>
                          </p>
                        </>
                      )}
                    </div>
                  </div>

                  
                </div>
                
              );
            })}
          </div>
          <div className="text-center">
  <Link
    to="/blog"
    className="btn puprple_btn right-link"
    data-aos="fade-in"
    data-aos-duration="1500"
  >
    Dónde estamos!
  </Link>
</div>
        </div>
      </section>
    </>
  );
};

export default Main;
