import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Bredcrumb from "../../Bredcrumb/Main";
import BGImg from "../../../assets/images/bread_crumb_bg.svg";
import BGImg1 from "../../../assets/images/bread_crumb_bg_one.png";
import BGImg2 from "../../../assets/images/bread_crumb_bg_two.png";

const Main = ({ brdcum }) => {
  const [faqCategory, setFaqCategory] = useState([]);
  const [activeFaq, setActiveFaq] = useState({});
  useEffect(() => {
    // fetch(`${process.env.REACT_APP_URL_API}/faq`, { method: "POST" })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     setFaqCategory(data.faq);
    //   });

    const data = {
      faq: [
        {
          category: "App",
          is_active: true,
          _id: "6477868b99f0f32c7a1ac6ed",
          display_name: "App",
          questions: [
            {
              answer:
                "- Descarga PagoDirecto en Google Play o App Store.\n</br>- Ingresa tus datos y verifica tu número de teléfono.\n</br>- Una vez creado tu usuario, deposita fondos para comenzar a usar.",
              question: "¿Cómo comienzo a usar la app?",
            },
            {
              answer:
                "- Tus Tickets de estacionamiento sin pasar por taquillas de pago.\n</br>- Peajes sin usar dinero en efectivo ni tarjetas de débito.\n</br>- Tu tiempo de estadía en parquímetros.\n</br> - Todos tus servicios básicos.",
              question: "¿Qué puedo pagar con PagoDirecto?",
            },
            {
              answer:
                "Actualmente en más de 70 estacionamientos en Caracas, Valencia, Maracay, San Antonio de los Altos y Portuguesa.\n</br> También en Peajes de Tachira y Portuguesa.",
              question: "¿Dónde puedo encontrarlos?",
            },
            {
              answer:
                "El único  momento donde se necesita señal de datos móviles es al momento de realizar el pago, para entrar o salir de los estacionamientos, solo se necesita tener activa la conexión bluetooth.",
              question: "¿Puedo usar aunque no tenga señal en mi telefóno?",
            },
          ],
          created_at: "2023-07-10T09:00:00.000Z",
        },
        {
          category: "Wallet",
          is_active: true,
          _id: "6477868b99f0f32c7a1ac6es",
          display_name: "Wallet",
          questions: [
            {
              answer:
                "Es una billetera virtual que te permitirá tener fondos dentro de la app y realizar pagos en todas las funciones y servicios que estén disponibles.",
              question: "¿Qué es?",
            },
            {
              answer:
                "Puedes depositar fondos desde distintos métodos: \n</br>- Pago móvil.\n</br>- Tarjetas Nacionales.\n</br> - Tarjetas Internacionales.\n</br> - Transferencias bancarias",
              question: "¿Cómo puedo depositar dinero en mi wallet?",
            },
          ],
          created_at: "2023-07-10T09:00:00.000Z",
        },
      ],
    };
    setFaqCategory(data.faq);
  }, []);

  return (
    <>
      {brdcum.b1 && (
        <Bredcrumb
          no={1}
          title="Preguntas frecuentes"
          paragraph=" "
          tag=""
          bgimg={BGImg}
        />
      )}

      {brdcum.b2 && (
        <Bredcrumb
          no={2}
          title="Have questions? look here"
          paragraph="Lorem Ipsum is simply dummy text of the printing and typesetting"
          tag="Faq"
          bgimg={BGImg1}
        />
      )}

      {brdcum.b5 && (
        <Bredcrumb
          no={5}
          title="Have questions? look here"
          paragraph="Lorem Ipsum is simply dummy text of the printing and typesetting"
          tag="Faq"
          bgimg={BGImg}
        />
      )}

      {brdcum.b3 && (
        <Bredcrumb
          no={3}
          title="Have questions? look here"
          paragraph="Lorem Ipsum is simply dummy text of the printing and typesetting"
          tag=""
        />
      )}

      {brdcum.b4 && (
        <Bredcrumb
          no={4}
          title="Have questions? look here"
          paragraph="Lorem Ipsum is simply dummy text of the printing and typesetting"
          tag="Faq"
          bgimg={BGImg2}
        />
      )}

      <section className="row_am faq_section">
        <div className="container">
          <div className="faq_panel">
            <div className="accordion" id="accordionExample">
              {faqCategory.map((faq, index) => {
                return (
                  <div
                    key={`a${index}`}
                    className="card"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    <div className="card-header" id={faq.display_name}>
                      <h2 className="mb-0">
                        <button
                          type="button"
                          className={`btn btn-link ${index && "active"}`}
                          onClick={() =>
                            setActiveFaq((prevState) => ({
                              ...prevState,
                              [index]: !prevState[index],
                            }))
                          }
                          data-toggle="collapse"
                          data-target={`#collapse${index}`}
                        >
                          {activeFaq[index] ? (
                            <i className="icon_faq icofont-minus"></i>
                          ) : (
                            <i className="icon_faq icofont-plus"></i>
                          )}{" "}
                          {faq.display_name}
                        </button>
                      </h2>
                    </div>
                    <div
                      id={`collapse${index}`}
                      className="collapse"
                      aria-labelledby={`heading${index}`}
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div
                          className="accordion"
                          id={`accordionExample${index}`}
                        >
                          {faq.questions.map((question, questionIndex) => {
                            return (
                              <div
                                key={`q${questionIndex}`}
                                className="card"
                                data-aos="fade-up"
                                data-aos-duration="1500"
                              >
                                <div
                                  className="card-header"
                                  id={question.question}
                                >
                                  <h2 className="mb-0">
                                    <button
                                      type="button"
                                      className={`btn btn-link ${
                                        questionIndex && "active"
                                      }`}
                                      onClick={() =>
                                        setActiveFaq((prevState) => ({
                                          ...prevState,
                                          [`${index}-${questionIndex}`]:
                                            !prevState[
                                              `${index}-${questionIndex}`
                                            ],
                                        }))
                                      }
                                      data-toggle="collapse"
                                      data-target={`#collapse${index}-${questionIndex}`}
                                    >
                                      {activeFaq[
                                        `${index}-${questionIndex}`
                                      ] ? (
                                        <i className="icon_faq icofont-minus"></i>
                                      ) : (
                                        <i className="icon_faq icofont-plus"></i>
                                      )}{" "}
                                      {question.question}
                                    </button>
                                  </h2>
                                </div>
                                <div
                                  id={`collapse${index}-${questionIndex}`}
                                  className="collapse"
                                  aria-labelledby={`heading${index}-${questionIndex}`}
                                  data-parent={`#accordionExample${index}`}
                                >
                                  <div
                                    className="card-body"
                                    dangerouslySetInnerHTML={{
                                      __html: question.answer,
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div style={{ height: "100px" }}></div>
          </div>
        </div>
      </section>
      {/* <section className="row_am free_app_section review_freeapp" id="getstarted">
            <div className="container">
                <div className="free_app_inner aos-init" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100"> 
                    <div className="anim_line dark_bg">
                        <span><img src="assets/images/anim_line.png" alt="anim_line" /></span>
                        <span><img src="assets/images/anim_line.png" alt="anim_line" /></span>
                        <span><img src="assets/images/anim_line.png" alt="anim_line" /></span>
                        <span><img src="assets/images/anim_line.png" alt="anim_line" /></span>
                        <span><img src="assets/images/anim_line.png" alt="anim_line" /></span>
                        <span><img src="assets/images/anim_line.png" alt="anim_line" /></span>
                        <span><img src="assets/images/anim_line.png" alt="anim_line" /></span>
                        <span><img src="assets/images/anim_line.png" alt="anim_line" /></span>
                        <span><img src="assets/images/anim_line.png" alt="anim_line" /></span>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="free_text">
                                <div className="section_title">
                                    <h2>Let’s download free from apple and play store</h2>
                                    <p>Instant free download from apple and play store orem Ipsum is simply dummy text of the printing.
                                    and typese tting indus orem Ipsum has beenthe standard</p>
                                </div>
                                <ul className="app_btn">
                                <li>
                                    <Link to="#">
                                    <img src="assets/images/appstore_blue.png" alt="image" />
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#">
                                    <img src="assets/images/googleplay_blue.png" alt="image" />
                                    </Link>
                                </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="free_img">
                                <img src="assets/images/download-screen01.png" alt="image" />
                                <img className="mobile_mockup" src="assets/images/download-screen02.png" alt="image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
    </>
  );
};

export default Main;
