import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Bredcrumb from "../../Bredcrumb/Main";
import PD_1 from "../../../assets/images/pd_1.jpg";
import PD_2 from "../../../assets/images/pd_2.jpg";
import PD_3 from "../../../assets/images/pd_3.jpg";
import BGImg from "../../../assets/images/bread_crumb_bg.svg";
import BGImg1 from "../../../assets/images/bread_crumb_bg_one.png";
import BGImg2 from "../../../assets/images/bread_crumb_bg_two.png";

const Main = ({ brdcum }) => {
  const [faqCategory, setFaqCategory] = useState([]);
  const [activeFaq, setActiveFaq] = useState({});
  useEffect(() => {
    // fetch(`${process.env.REACT_APP_URL_API}/faq`, { method: "POST" })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     setFaqCategory(data.faq);
    //   });

    const data = {
      faq: [
        {
          category: "App",
          is_active: true,
          _id: "6477868b99f0f32c7a1ac6ed",
          display_name: "Paso 1.",
          questions: [
            {
              answer:
                "- Iniciar session en la App de  PagoDirecto App y luego  tocar sobre el nombre de usuario.",
              question:
                "Iniciar session en la App de  PagoDirecto App y luego  tocar sobre el nombre de usuario",
            },
          ],
          created_at: "2023-07-10T09:00:00.000Z",
        },
        {
          category: "Apps",
          is_active: true,
          _id: "6477868b99f0f32c7a1ac6ss",
          display_name: "Paso 2.",
          questions: [
            {
              answer:
                "- Iniciar session en la App de  PagoDirecto App y luego  tocar sobre el nombre de usuario.",
              question: "Tap [Legal] - [Eliminar cuenta].",
            },
          ],
          created_at: "2023-07-10T09:00:00.000Z",
        },
        {
          category: "Wallet",
          is_active: true,
          _id: "6477868b99f0f32c7a1ac6es",
          display_name: "Paso 2.",
          questions: [
            {
              answer:
                "Es una billetera virtual que te permitirá tener fondos dentro de la app y realizar pagos en todas las funciones y servicios que estén disponibles.",
              question: "¿Qué es?",
            },
            {
              answer:
                "Puedes depositar fondos desde distintos métodos: \n</br>- Pago móvil.\n</br>- Tarjetas Nacionales.\n</br> - Tarjetas Internacionales.\n</br> - Transferencias bancarias",
              question: "¿Cómo puedo depositar dinero en mi wallet?",
            },
          ],
          created_at: "2023-07-10T09:00:00.000Z",
        },
      ],
    };
    setFaqCategory(data.faq);
  }, []);

  return (
    <>
      {brdcum.b1 && (
        <Bredcrumb
          no={1}
          title="Política de Privacidad"
          paragraph=" "
          tag=""
          bgimg={BGImg}
        />
      )}

      <section className="row_am faq_section">
        <div className="container">
          <div className="faq_panel">
            <div className="card my-5" data-aos-duration="1500">
              <div
                className="card-header"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div className="mb-4">
                  <h2
                    className="mb-0 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    INTRODUCCIÓN
                  </h2>

                  <p>
                    <span style={{ fontWeight: "bold" }}>DIRECTPAY C.A</span>,
                    identificada con el RIF J505464299, (en lo sucesivo, “
                    <span style={{ fontWeight: "bold" }}>PagoDirecto</span>”),
                    se compromete a proteger tu privacidad. Esta Política de
                    Privacidad describe cómo recopilamos, usamos, divulgamos,
                    protegemos y administramos tu información personal cuando
                    utilizas nuestra plataforma tecnológica (en lo sucesivo web
                    y/o App).
                  </p>
                </div>

                <div className="mb-4">
                  <h2
                    className="mb-0 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    INFORMACIÓN RECOPILADA
                  </h2>

                  <div>
                    <ul style={{ paddingLeft: "", listStyleType: "disc" }}>
                      <li>
                        •{" "}
                        <span style={{ fontWeight: "bold" }}>
                          Información proporcionada por el usuario:
                        </span>{" "}
                        PagoDirecto almacena los datos provistos por el Usuario
                        al momento del registro:
                        <ul
                          style={{ paddingLeft: "20px", listStyleType: "disc" }}
                        >
                          <li>• Correo electrónico.</li>
                          <li>• Documento de identidad.</li>
                          <li>• Número de teléfono.</li>
                        </ul>
                      </li>

                      <li>
                        •{" "}
                        <span style={{ fontWeight: "bold" }}>
                          Información recopilada automáticamente:
                        </span>
                        <ul
                          style={{ paddingLeft: "20px", listStyleType: "disc" }}
                        >
                          <li>• Correo electrónico.</li>
                          <li>• Documento de identidad.</li>
                          <li>• Número de teléfono.</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="mb-4">
                  <h2
                    className="mb-0 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    USO DE LA INFORMACIÓN
                  </h2>
                  <p>PagoDirecto utiliza la información recopilada para:</p>

                  <ul style={{ paddingLeft: "10px", listStyleType: "disc" }}>
                    <li>• Proporcionar y mejorar la plataforma tecnológica.</li>
                    <li>• Personalizar la experiencia de usuario.</li>
                    <li>
                      • Comunicarse con los usuarios, incluyendo actualizaciones
                      de la plataforma tecnológica, notificaciones, ofertas
                      especiales y mensajes promocionales.
                    </li>
                    <li>
                      • Analizar el uso de la plataforma tecnológica y mejorar
                      su funcionalidad.
                    </li>
                    <li>
                      • Prevenir y detectar actividades fraudulentas o ilegales.
                    </li>
                    <li>• Cumplir con las leyes y regulaciones aplicables.</li>
                  </ul>
                </div>

                <div className="mb-4">
                  <h2
                    className="mb-0 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    DIVULGACIÓN DE INFORMACIÓN
                  </h2>
                  <p>
                    PagoDirecto podrá suministrar información del usuario cuando
                    sean requeridos por autoridades legales para cumplir con las
                    leyes, regulaciones o solicitudes legales. Al crear una
                    cuenta en su sistema, PagoDirecto se compromete a no vender,
                    alquilar o compartir con terceros la información
                    suministrada por el Usuario.
                  </p>
                </div>
                <div className="mb-4">
                  <h2
                    className="mb-0 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    MENORES DE EDAD
                  </h2>
                  <p>
                    La plataforma tecnológica de PagoDirecto no está dirigida a
                    niños menores de edad. No recopilamos información personal
                    de menores de edad. Si te enteras de que un menor de nos ha
                    proporcionado información personal, nos puede contactar a
                    través de nuestra plataforma tecnológica.
                  </p>
                </div>
                <div className="mb-4">
                  <h2
                    className="mb-0 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    CLÁUSULA DE VERIFICACIÓN DE IDENTIDAD
                  </h2>
                  <ul style={{ paddingLeft: "10px", listStyleType: "disc" }}>
                    <li>
                      • PagoDirecto podrá confirmar los datos personales
                      suministrados acudiendo a entidades públicas, compañías
                      especializadas o centrales de riesgo, para lo cual el
                      Usuario la autoriza expresamente.{" "}
                    </li>
                    <li>
                      • PagoDirecto se reserva el derecho de solicitar
                      documentos adicionales en caso de que se considere
                      necesario verificar más información del Usuario registrado
                      en su plataforma tecnológicas. De igual forma, se reserva
                      el derecho de suspender temporal o definitivamente a aquel
                      Usuario cuyos datos reflejen operaciones sospechosas.
                    </li>
                  </ul>
                </div>

                <div className="mb-4">
                  <h2
                    className="mb-0 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    CLÁUSULA DE EXENCIÓN DE RESPONSABILIDAD
                  </h2>
                  <p>
                    PagoDirecto no se hace responsable bajo ningún concepto por
                    daños y perjuicios de cualquier naturaleza que puedan
                    suscitarse por la errada, falta de veracidad, vigencia y/o
                    autenticidad de la información suministrada. PagoDirecto
                    tampoco se hace responsable por los daños y perjuicios de
                    cualquier naturaleza que puedan deberse a la suplantación de
                    la personalidad de un tercero efectuada por un Usuario en
                    cualquier clase de comunicación o transacción realizada a
                    través del portal y servicio ofrecido por PagoDirecto.
                  </p>
                  <p>
                    PagoDirecto no se hace responsable por interceptaciones
                    ilegales o violación de sus sistemas o bases de datos por
                    parte de personas no autorizadas y tampoco se hace
                    responsable por la indebida utilización de la información
                    obtenida por esos medios.
                  </p>
                </div>
                <div className="mb-4">
                  <h2
                    className="mb-0 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    PROCEDIMIENTOS DE SEGURIDAD
                  </h2>
                  <p>
                    PagoDirecto se compromete a proteger la privacidad y
                    seguridad de los datos personales de sus usuarios. Para
                    ello, están implementados los siguientes procedimientos
                    seguros de manejo de datos:
                  </p>

                  <ul style={{ paddingLeft: "10px", listStyleType: "disc" }}>
                    <li>
                      • Recopilación únicamente de la información personal
                      necesaria para la prestación de nuestros servicios. No
                      solicitamos información sensible a menos que sea
                      estrictamente necesario y con el consentimiento explícito
                      del usuario.
                    </li>
                    <li>
                      • Utilizamos bases de datos seguras y encriptadas para
                      almacenar la información personal de nuestros usuarios,
                      con acceso restringido a personal autorizado.
                    </li>
                    <li>
                      • Realizamos copias de seguridad periódicas de nuestros
                      datos para asegurar su recuperación en caso de un
                      incidente.
                    </li>
                    <li>
                      • PagoDirecto utiliza encriptación de extremo a extremo
                      (datos cifrados mediante SSL/TLS) para proteger la
                      información personal de nuestros usuarios durante la
                      transmisión a través de nuestra plataforma tecnológica.
                    </li>
                    <li>
                      • En caso de que ocurra una violación de la seguridad que
                      afecte la información personal de nuestros usuarios,
                      notificaremos a los usuarios afectados con prontitud.
                    </li>
                  </ul>
                </div>

                <div className="mb-4">
                  <h2
                    className="mb-0 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    ELIMINACIÓN DE DATOS
                  </h2>
                  <p>
                    El usuario tiene derecho de darse de baja y eliminar todos
                    sus datos personales de nuestra plataforma tecnológica. En
                    el siguiente URL se detallan los pasos de como eliminar los
                    datos personales y darse de baja:{" "}
                    <Link to="/uninstall" style={{ color: "blue" }}>
                      https://web.pagodirecto.com/uninstall
                    </Link>.
                  </p>
                </div>

                <div className="mb-4">
                  <h2
                    className="mb-0 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    VERIFICACIÓN ADICIONAL DE IDENTIDAD
                  </h2>
                  <p>
                    PagoDirecto se reserva el derecho de solicitar documentos
                    adicionales en caso de que se considere necesario verificar
                    más información del Usuario registrado en su plataforma
                    tecnológica. De igual forma, se reserva el derecho de
                    suspender temporal o definitivamente a aquel Usuario cuyos
                    datos reflejen operaciones sospechosas.
                  </p>
                  <p>
                    PagoDirecto no se hace responsable por ningún daño o
                    perjuicio ocasionado por personas que no cumplan con esta
                    condición y la respectiva cuenta podrá ser eliminada de
                    forma inmediata.
                  </p>
                </div>
                <div className="mb-4">
                  <h2
                    className="mb-0 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    ACUERDO CON LA POLÍTICA DE SEGURIDAD
                  </h2>
                  <p>
                    Al utilizar nuestra plataforma tecnológica, aceptas los
                    términos de esta Política de Privacidad. Si no estás de
                    acuerdo con los términos de esta Política de Privacidad, no
                    uses la plataforma tecnológica ofrecida por PagoDirecto.
                  </p>
                </div>
                <div className="mb-4">
                  <h2
                    className="mb-0 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    CAMBIOS A ESTA POLÍTICA
                  </h2>
                  <p>
                    PagoDirecto se reserva el derecho de modificar esta Política
                    de Privacidad. Cualquier cambio se indicará mediante una
                    fecha de revisión actualizada. El uso continuo de nuestra
                    aplicación después de dichos cambios constituye la
                    aceptación de la nueva política.
                  </p>
                </div>
                <div className="mb-4">
                  <h2
                    className="mb-0 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    CONTACTO
                  </h2>
                  <p>
                    En caso de que tenga alguna inquietud o consulta en relación
                    con esta Política de Privacidad, puede ponerse en contacto
                    con PagoDirecto a través de nuestra sección "Contáctanos" de
                    nuestra página web, en la opción del menú “Soporte” en
                    nuestra App PagoDirecto o a través de nuestras redes
                    sociales oficiales.
                  </p>
                </div>
                <div>
                  <h2
                    className="mb-0 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    ENTRADA EN VIGENCIA
                  </h2>
                  <p>
                    Fecha de entrada en vigor: el día quince (15) de agosto del
                    año dos mil veinticuatro (2024). Queda sin efecto toda
                    declaración de Política de Privacidad emitida con fecha
                    anterior.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div style={{ height: "500px" }}></div>
        </div>
      </section>
    </>
  );
};

export default Main;
