import React from "react";
import img1 from "../../../assets/images/mov-telefono.svg";
// import img2 from "../../../assets/images/secure_data.png";
// import img3 from "../../../assets/images/modern02.png";
// import img4 from "../../../assets/images/modern03.png";

const Main = ({ gredient }) => {
  return (
    <>
      <section
        id="wallet"
        className={`row_am modern_ui_section ${gredient && "gredient-bg"}`}
      >
        {gredient && (
          <div className="modernui_section_bg modernui-gredient">
            {" "}
            {/* <img src="assets/images/section-bg.png" alt="image" />{" "} */}
          </div>
        )}
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="ui_text">
                <div
                  className="section_title"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-delay="100"
                >
                  <h2 className="sub-title-mb">
                    <span>Wallet</span>
                  </h2>
                  <p>
                    Disponemos de una
                    billetera digital que brinda seguridad y respaldo de tus
                    transacciones diarias.
                  </p>
                </div>
                <ul className="design_block">
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <h4>Transferencias entre usuarios PagoDirecto</h4>
                    <p>De manera rápida en cuestión de segundos.</p>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <h4>Cash-in inmediato</h4>
                    <p>
                      Mediante pago móvil y tarjetas nacionales.
                    </p>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <h4>Robustez transaccional</h4>
                    <p>
                      Con la seguridad de una institución bancaria líder en el
                      mercado venezolano.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="ui_images"
                data-aos="fade-in"
                data-aos-duration="3000"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="wallet_img">
                  <img
                    className="moving_position_animatin"
                    src={img1}
                    alt="bancamiga_wallet"
                  />

                  {/* <img className="moving_position_animatin" src={img2} alt="image" />
                            <img className="moving_position_animatin" src={img3} alt="image" />
                            <img className="moving_position_animatin" src={img4} alt="image" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;
