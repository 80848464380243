import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Bredcrumb from "../../Bredcrumb/Main";
import PD_1 from "../../../assets/images/pd_1.jpg";
import PD_2 from "../../../assets/images/pd_2.jpg";
import PD_3 from "../../../assets/images/pd_3.jpg";
import BGImg from "../../../assets/images/bread_crumb_bg.svg";
import BGImg1 from "../../../assets/images/bread_crumb_bg_one.png";
import BGImg2 from "../../../assets/images/bread_crumb_bg_two.png";

const Main = ({ brdcum }) => {
  const [faqCategory, setFaqCategory] = useState([]);
  const [activeFaq, setActiveFaq] = useState({});
  useEffect(() => {
    // fetch(`${process.env.REACT_APP_URL_API}/faq`, { method: "POST" })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     setFaqCategory(data.faq);
    //   });

    const data = {
      faq: [
        {
          category: "App",
          is_active: true,
          _id: "6477868b99f0f32c7a1ac6ed",
          display_name: "Paso 1.",
          questions: [
            {
              answer:
                "- Iniciar session en la App de  PagoDirecto App y luego  tocar sobre el nombre de usuario.",
              question:
                "Iniciar session en la App de  PagoDirecto App y luego  tocar sobre el nombre de usuario",
            },
          ],
          created_at: "2023-07-10T09:00:00.000Z",
        },
        {
          category: "Apps",
          is_active: true,
          _id: "6477868b99f0f32c7a1ac6ss",
          display_name: "Paso 2.",
          questions: [
            {
              answer:
                "- Iniciar session en la App de  PagoDirecto App y luego  tocar sobre el nombre de usuario.",
              question: "Tap [Legal] - [Eliminar cuenta].",
            },
          ],
          created_at: "2023-07-10T09:00:00.000Z",
        },
        {
          category: "Wallet",
          is_active: true,
          _id: "6477868b99f0f32c7a1ac6es",
          display_name: "Paso 2.",
          questions: [
            {
              answer:
                "Es una billetera virtual que te permitirá tener fondos dentro de la app y realizar pagos en todas las funciones y servicios que estén disponibles.",
              question: "¿Qué es?",
            },
            {
              answer:
                "Puedes depositar fondos desde distintos métodos: \n</br>- Pago móvil.\n</br>- Tarjetas Nacionales.\n</br> - Tarjetas Internacionales.\n</br> - Transferencias bancarias",
              question: "¿Cómo puedo depositar dinero en mi wallet?",
            },
          ],
          created_at: "2023-07-10T09:00:00.000Z",
        },
      ],
    };
    setFaqCategory(data.faq);
  }, []);

  return (
    <>
      {brdcum.b1 && (
        <Bredcrumb
          no={1}
          title="Términos y Condiciones de PagoDirecto"
          paragraph=" "
          tag=""
          bgimg={BGImg}
        />
      )}

      <section className="row_am faq_section">
        <div className="container">
          <div className="faq_panel">
            <div className="card my-5" data-aos-duration="1500">
              <div
                className="card-header"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div className="mb-4">
                  <p>
                    Por favor, se requiere que usted lea estas condiciones
                    generales con detenimiento, por cuanto serán las condiciones
                    bajo las cuales se va a regir el servicio prestado por
                    Corporación Paytech, C.A. (en lo sucesivo PagoDirecto), así
                    como el uso de su plataforma electrónica (en lo sucesivo
                    sitio web y/o app).
                  </p>

                  <p>
                    Los servicios que ofrece PagoDirecto se encuentran
                    disponibles únicamente para aquellas personas que tengan
                    capacidad legal para contratarlos; esto implica, que se
                    trate de personas mayores de edad y que no presenten ningún
                    impedimento para celebrar contratos. Por lo tanto, aquellos
                    que no cumplan con esta condición, deberán abstenerse de
                    crear una cuenta en el sistema y de usar los servicios
                    ofrecidos por PagoDirecto.
                  </p>
                  <p>
                    PagoDirecto no se hace responsable por ningún daño o
                    perjuicio ocasionado por personas que no cumplan con esta
                    condición y la respectiva cuenta podrá ser eliminada de
                    forma inmediata.
                  </p>

                  <p>
                    El Usuario no podrá ceder total o parcialmente este contrato
                    o los derechos contenidos en él.
                  </p>
                </div>

                <div className="mb-4">
                  <h2
                    className="mb-3 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    1. Productos y/o Servicios ofrecidos por PagoDirecto:
                  </h2>

                  <div>
                    <ul
                      className="pl-3 "
                      style={{ paddingLeft: "", listStyleType: "disc" }}
                    >
                      <li className="mb-3 ">
                        <span className="" style={{ fontWeight: "bold" }}>
                          a)
                        </span>{" "}
                        Estacionamiento: Se trata de una herramienta por la cual
                        se digitaliza el flujo tradicional de los
                        estacionamientos, con nuestro sistema bluetooth,
                        permitiendo entrar, pagar y salir al usuario usando sólo
                        su teléfono inteligente.
                      </li>
                      <li className="mb-3">
                        <span className="" style={{ fontWeight: "bold" }}>
                          b)
                        </span>{" "}
                        Servicios: Se trata de una función que permite el pago
                        de los servicios telefónicos, internet y televisión que
                        tiene contratados el usuario.
                      </li>
                      <li className="mb-3">
                        <span className="" style={{ fontWeight: "bold" }}>
                          c)
                        </span>{" "}
                        Parquímetros: Se trata de una función que permite la
                        utilización de parquímetros digitales para las calles de
                        nuestras ciudades, brindando una solución escalable y de
                        pago automatizado a través de teléfonos y puntos de
                        ventas autorizados.
                      </li>
                      <li className="mb-3">
                        <span className="" style={{ fontWeight: "bold" }}>
                          d)
                        </span>{" "}
                        Peajes: Se trata de un método de pago que permite al
                        usuario, a través de nuestra tecnología bluetooth,
                        realizar el pago de peaje en las distintas carreteras,
                        autopistas, calles o vías que tengan esta modalidad.
                      </li>
                      <li className="mb-3">
                        <span className="" style={{ fontWeight: "bold" }}>
                          e)
                        </span>{" "}
                        Eventos: Se trata de una función que permite contratar y
                        pagar la asistencia o participación en eventos
                        recreativos, de entretenimiento, culturales, deportivos
                        o sociales promovidos o bajo responsabilidad de
                        terceros.
                      </li>
                      <li className="mb-3">
                        <span className="" style={{ fontWeight: "bold" }}>
                          f)
                        </span>{" "}
                        Premium: Paquetes especiales de consumos prepagados con
                        una duración mensual o mayor disponibles para usuarios
                        frecuentes del producto y/o servicio de estacionamiento,
                        previo convenio con el proveedor del producto y/o
                        servicio de estacionamiento.
                      </li>
                      <li className="mb-3">
                        <span className="" style={{ fontWeight: "bold" }}>
                          g)
                        </span>{" "}
                        Pago de consumos escolares: Servicio que permite a un
                        padre o representante de un niño, niña o adolescente
                        afiliar su wallet a un brazalete con código QR, el cual
                        permite realizar pagos de consumos en establecimientos
                        ubicados en colegios o planteles educativos, con cargo
                        al wallet del padre o representante.
                      </li>
                      <li className="mb-3">
                        <span className="" style={{ fontWeight: "bold" }}>
                          h)
                        </span>{" "}
                        Cualquier otro producto y/o servicio que en el futuro se
                        incorpore dentro de la aplicación de PagoDirecto.
                      </li>
                    </ul>
                    <p>
                      Por cada producto y/o servicio prestado por PagoDirecto,
                      el usuario autoriza y conviene el pago de una tarifa, la
                      cual será indicada expresamente y por separado, en
                      aquellos casos en que el costo de los productos y/o
                      servicio no incorpore dentro de su costo final la tarifa
                      de PagoDirecto.
                    </p>
                  </div>
                </div>

                <div className="mb-4">
                  <h2
                    className="mb-3 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    2. Mecanismos utilizados por PagoDirecto para ofrecer sus
                    productos y/o prestar sus servicios a los usuarios:
                  </h2>

                  <div>
                    <ul
                      className="pl-3"
                      style={{ paddingLeft: "", listStyleType: "disc" }}
                    >
                      <li className="mb-3">
                        <span className="" style={{ fontWeight: "bold" }}>
                          a)
                        </span>{" "}
                        APP: Es aplicativo que el usuario puede descargar en
                        Google Play o App Store, en la cual ingresará sus datos,
                        se verificará su número de teléfono; y posteriormente
                        una vez creado el usuario, le permitirá el depósito de
                        fondos, a través de una entidad bancaria.
                      </li>
                      <li className="mb-3">
                        <span className="" style={{ fontWeight: "bold" }}>
                          b)
                        </span>{" "}
                        Wallet: Es una billetera virtual bajo responsabilidad de
                        Bancamiga, Banco Universal, conforme a una alianza
                        existente entre esa entidad bancaria y PagoDirecto, que
                        le permite al usuario tener fondos dentro de la app y
                        realizar pagos en todas las funciones y servicios que
                        estén disponibles. Para tener una cuenta en la wallet,
                        no necesariamente el usuario debe tener una cuenta en
                        Bancamiga. Los fondos estarán disponibles en la wallet a
                        través de distintas formas de recarga, entre ellas, pago
                        móvil, tarjetas nacionales o internacionales y
                        transferencias bancarias. La cuenta principal estará a
                        nombre de PagoDirecto en Bancamiga y cada wallet actuará
                        como una subcuenta a nombre de cada usuario. Toda
                        recarga que se haga con una tarjeta o aplicación en
                        divisas se convertirá en Bolívares a la tasa del cambio
                        vigente publicada por el BCV para la fecha de la
                        operación.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="mb-4">
                  <h2
                    className="mb-3 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    3. Condiciones Generales de Operación.
                  </h2>
                  <div className="ml-3">
                    <div>
                      {" "}
                      <h2
                        className="mb-0 btn  "
                        style={{ textAlign: "left", alignSelf: "flex-start" }}
                      >
                        3.1. Naturaleza de PagoDirecto:
                      </h2>
                      <p>
                        PagoDirecto es una empresa cuyo objeto es desarrollar
                        aplicaciones móviles y sistemas IoT para entidades
                        financieras, telecomunicaciones y comercios mayoristas y
                        minoristas.
                      </p>
                      <p>
                        PagoDirecto desarrolló un módulo de estacionamiento
                        llamado MTD (Módulo de Ticket Digital, en lo sucesivo
                        Ticket Digital) para poder ingresar a los
                        estacionamientos con solo tener un teléfono inteligente,
                        sin la necesidad de un sensor NFC o una wallet
                        prepagada. El Usuario recibirá un ticket digital.
                      </p>
                      <p>
                        PagoDirecto no es bajo ningún concepto una entidad
                        financiera ni presta al Usuario ningún servicio
                        bancario, financiero o cambiario ni relacionado con esas
                        actividades.
                      </p>
                      <p>
                        De igual forma, queda expresamente entendido que
                        PagoDirecto no es una compañía prestadora directa o
                        indirecta del servicio de estacionamiento o garaje, así
                        como tampoco servicios conexos o inherentes con esa
                        actividad, con lo cual, PagoDirecto no se encuentra
                        sometida a la legislación atinente a la prestación de
                        ese servicio, ni está sujeta a las regulaciones y
                        requisitos que las empresas prestadoras del servicio de
                        estacionamiento deben cumplir, incluyendo regulaciones
                        de precios y demás condiciones de prestación de tal
                        servicio.
                      </p>
                      <p>
                        PagoDirecto no realiza operaciones de intermediación
                        financiera, de otorgamiento de créditos o de
                        financiamiento. Tampoco capta fondos de ningún tipo y
                        bajo ningún concepto. No hace inversiones, ni presta
                        servicios de asesoramiento financiero o de inversiones.
                        Tampoco ofrece servicios auxiliares bancarios ni emite
                        ni administra ningún tipo de tarjetas de crédito,
                        débito, prepago, financiamiento y pago electrónico. En
                        fin, no desarrolla actividades reservadas exclusivamente
                        a instituciones del sector bancario, las cuales están
                        reguladas por la Superintendencia de las Instituciones
                        del Sector Bancario (SUDEBAN).
                      </p>
                      <p>
                        PagoDirecto mantiene total independencia jurídica y
                        financiera con cualquier institución bancaria en la
                        República Bolivariana de Venezuela y/o en el exterior.
                        No existe relación laboral, de dependencia, agencia,
                        representación, ni responsabilidad solidaria entre
                        PagoDirecto y cualquier institución bancaria, centro
                        comercial o estacionamiento. Las obligaciones y derechos
                        derivados de la prestación de servicios de la plataforma
                        de PagoDirecto son única y exclusivamente asumidos por
                        ésta última y en ningún caso podrán ser entendidos como
                        asumidos por otra persona natural o jurídica.
                      </p>
                      <p>
                        El ticket digital será emitido por el módulo MTD en el
                        momento de ingreso al estacionamiento, el cual deberá
                        ser pagado por el Usuario a través de la wallet
                      </p>
                    </div>

                    <div className="mb-4">
                      <h2
                        className="mb-0 btn  "
                        style={{ textAlign: "left", alignSelf: "flex-start" }}
                      >
                        3.2. De los Estacionamientos Afiliados:
                      </h2>
                      <p>
                        Los Estacionamientos Afiliados son estacionamientos
                        públicos y/o privados afiliados al sistema en línea de
                        PagoDirecto que ofrecen al Usuario y a terceras personas
                        el servicio de estacionamiento para vehículos
                        automotores y que, en virtud de un convenio con
                        PagoDirecto, permitirán el uso de la app y el ticket
                        digital sustituyendo el ticket de papel, por parte del
                        Usuario. La lista de los Estacionamientos Afiliados
                        estará publicada en la app o página web de PagoDirecto y
                        podrá ser actualizada permanentemente.
                      </p>
                      <p>
                        El Usuario deberá siempre cumplir con las leyes y
                        normativas aplicables, así como con las normas
                        establecidas por el correspondiente estacionamiento
                        afiliado, o que por otro concepto tengan relación con el
                        lugar donde el Usuario estacione su vehículo.
                      </p>
                      <p>
                        El Usuario será el único responsable de aparcar su
                        vehículo dentro del estacionamiento afiliado, así como
                        de hacerlo en la forma correspondiente, sin obstaculizar
                        a otros vehículos, dentro del espacio demarcado para
                        tales fines, y sin causar daños a terceros o al propio
                        estacionamiento afiliado. De igual forma, el usuario
                        será el único responsable por las infracciones de
                        tránsito en que incurra dentro de las áreas del
                        estacionamiento afiliado.
                      </p>
                      <p>
                        El usuario será igualmente responsable de la seguridad
                        de su vehículo y por lo tanto tomará medidas razonables
                        para evitar que no se produzca el hurto de su vehículo,
                        piezas del mismo o de las pertenencias que haya dejado
                        dentro del vehículo.
                      </p>
                      <p>
                        El usuario se compromete y se hace responsable de salir
                        del estacionamiento con el mismo vehículo que entró; no
                        pudiendo hacer uso del ticket electrónico para otro
                        vehículo distinto o para otro usuario. Cualquier
                        infracción de este tipo, libera de toda responsabilidad
                        a PagoDirecto.
                      </p>
                    </div>
                    <div className="mb-4">
                      <h2
                        className="mb-0 btn  "
                        style={{ textAlign: "left", alignSelf: "flex-start" }}
                      >
                        3.3. De los Servicios y/o Productos de Recargas
                        Telefónicas, Pago de Servicio Telefónico y /o Pago de
                        Suscripción de Televisión o Cable.
                      </h2>
                      <p>
                        El usuario será responsable de los datos del contrato o
                        numero de identificación del servicio que coloque en la
                        aplicación para realizar el pago o recarga. PagoDirecto
                        no será responsable de tales datos suministrados por el
                        usuario.
                      </p>
                      <p>
                        El costo de la recarga o saldo deudor del servicio,
                        incluyendo los montos de las recargas, serán provistos
                        por la empresa que preste el servicio telefónico o de
                        televisión. PagoDirecto no participa en la fijación de
                        las tarifas ni tampoco cobrará recargo alguno por el
                        proceso de recarga o pago del servicio.
                      </p>
                      <p>
                        PagoDirecto no será responsable de la prestación ni de
                        la calidad del servicio telefónico o de televisión,
                        debido a que PagoDirecto sólo es una plataforma para la
                        realización de la recarga o pago.
                      </p>
                      <p>
                        PagoDirecto no será responsable si una recarga o pago no
                        se puede realizar debido a que la plataforma del
                        proveedor del servicio se encuentra fuera de línea, sin
                        acceso, sin actividad o presenta fallas de
                        funcionamiento.
                      </p>
                    </div>

                    <div className="mb-4">
                      <h2
                        className="mb-0 btn  "
                        style={{ textAlign: "left", alignSelf: "flex-start" }}
                      >
                        3.4. De los servicios y/o productos de pago de
                        parquímetros o peajes.
                      </h2>
                      <p>
                        PagoDirecto no es responsable del estado y/o las
                        condiciones de operación de los parquímetros o peajes.
                        Ello será responsabilidad del concesionario, organismo o
                        ente público o privado que administre tales unidades
                        recaudadoras. Tampoco será responsable por los daños que
                        tales parquímetros, peajes o terceros puedan ocasionar a
                        los vehículos automotores de los usuarios.
                      </p>
                      <p>
                        El usuario correrá con todos los riesgos al estacionar
                        su vehículo en un área de parquímetro o pasar por un
                        peaje. Si incumple con los horarios, pagos o condiciones
                        de aparcamiento o de peaje, el usuario será el único
                        responsable si su vehículo es remolcado y trasladado a
                        un estacionamiento público, así como las sanciones que
                        reciba por tales hechos.
                      </p>
                    </div>
                    <div className="mb-4">
                      <h2
                        className="mb-0 btn  "
                        style={{ textAlign: "left", alignSelf: "flex-start" }}
                      >
                        3.5. De los servicios y/o productos de eventos.
                      </h2>
                      <p>
                        PagoDirecto no será responsable de la calidad de los
                        eventos ni tampoco si tales eventos resultan cancelados
                        o suspendidos por razones económicas, fuerza mayor, caso
                        fortuito o decisión de sus patrocinantes o autoridades
                        públicas.
                      </p>
                    </div>

                    <div className="mb-4">
                      <h2
                        className="mb-0 btn  "
                        style={{ textAlign: "left", alignSelf: "flex-start" }}
                      >
                        3.6. Pago de cantinas o establecimientos de consumo
                        escolares.
                      </h2>
                      <p>
                        PagoDirecto podrá cobrar el costo de reposición del
                        brazalete por causa de deterioro o perdida.
                      </p>
                      <p>
                        PagoDirecto no será responsable por los consumos que
                        realice un niño, niña o adolescente, ni responderá de la
                        calidad o cantidad de los consumos que realice. Los
                        padres o representantes legales fijaran las condiciones
                        de consumos para sus hijos.
                      </p>
                    </div>
                    <div className="mb-4">
                      <h2
                        className="mb-0 btn  "
                        style={{ textAlign: "left", alignSelf: "flex-start" }}
                      >
                        3.7. Publicidad de terceros.
                      </h2>
                      <p>
                        PagoDirecto podrá enviar a sus usuarios publicidad sobre
                        productos y/o servicios ofrecidos por terceros y podrá
                        proveer su plataforma para que el usuario realice el
                        pago de sus consumos. En tales casos, PagoDirecto no
                        será responsable de la calidad del producto o servicio
                        ofrecidos por terceros.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mb-4">
                  <h2
                    className="mb-0 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    4. Uso del sitio web y derechos de propiedad intelectual.
                  </h2>
                  <p>
                    El Usuario acuerda utilizar los servicios ofrecidos por
                    PagoDirecto solo con fines permitidos por la ley.
                    PagoDirecto se reserva el derecho de terminar el servicio y
                    eliminar la cuenta del Usuario registrado inmediatamente y
                    sin notificación previa si, a su exclusivo criterio,
                    considera que el Usuario ha transgredido este artículo,
                    haciendo al Usuario responsable de todos los cargos que se
                    generen. En caso de sospecha o confirmación de estas
                    actividades, PagoDirecto se reserva el derecho de
                    denunciarlo a las autoridades competentes y proporcionar
                    todos los datos relacionados con la cuenta y las
                    transacciones. PagoDirecto no se hace en ningún momento
                    responsable por estas operaciones, daños, perjuicios o
                    inconvenientes causados y queda totalmente eximido de
                    cualquier carga, responsabilidad o daño causado.
                  </p>
                  <p>
                    Todos los derechos intelectuales, marcarios e industriales,
                    incluidos también los derechos de autor, sobre el sitio web,
                    la plataforma, desarrollo, software, hardware, dominio,
                    logos, emblemas, logotipos, diseños, estructura, contenidos,
                    información, entre otros, son de propiedad exclusiva de
                    PagoDirecto. En ningún caso se entenderá que el Usuario o
                    los Estacionamientos Afiliados tendrán algún tipo de derecho
                    sobre los mismos, independientemente de los derechos
                    conferidos en la plataforma que PagoDirecto pone a
                    disposición del Usuario. Cualquier uso no autorizado le dará
                    derecho a PagoDirecto a resolver de pleno derecho este
                    contrato y, adicionalmente, a ejercer las acciones legales
                    correspondientes.
                  </p>
                </div>

                <div className="mb-4">
                  <h2
                    className="mb-0 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    5. Registro del Usuario.
                  </h2>
                  <p>
                    Para acceder a los servicios ofrecidos por PagoDirecto, el
                    Usuario deberá crear una cuenta en la app, la cual será
                    única e intransferible. Para ello, deberá proporcionar sus
                    datos personales completando un formulario dispuesto en la
                    sección de registro a tal fin. El Usuario se compromete a
                    proporcionar información veraz y actualizada.
                  </p>
                  <p>
                    Cualquier información incompleta o errónea será exclusiva
                    responsabilidad del Usuario.
                  </p>
                  <p>
                    PagoDirecto se encuentra en su derecho de rechazar la
                    creación o suspender temporal o definitivamente las cuentas
                    y/o usuario que considere que no cumplan o infrinjan lo
                    establecido en estos Términos y Condiciones de Uso.
                  </p>
                  <p>
                    Los servicios están destinados a personas jurídicas o a
                    personas naturales o físicas mayores de 18 años de edad.
                  </p>
                  <p>
                    Al solicitar el registro en PagoDirecto, el Usuario acepta y
                    acuerda quedar vinculado por los presentes Términos y
                    Condiciones.
                  </p>
                  <p>
                    Cuando el registro del Usuario haya sido completado, éste
                    recibirá un código de verificación que le permitirá al
                    Usuario acceder a los servicios de PagoDirecto.
                  </p>
                  <p>
                    Ciertos servicios requieren que el Usuario haga uso de la
                    App y haya habilitado las funciones “permitir acceso a mi
                    ubicación” y/o “permitir notificaciones automáticas” en su
                    teléfono móvil u otras soluciones técnicas aprobadas por
                    PagoDirecto. En estos casos se entenderá que el Usuario lo
                    autoriza por el hecho de hacer uso de la App.
                  </p>
                  <p>
                    El Usuario será responsable de asegurarse de que el teléfono
                    móvil inteligente, phablet o tablet, que utilice para
                    acceder a los servicios funcione adecuadamente y sea en todo
                    momento compatible con PagoDirecto, los Servicios y la App,
                    según sea el caso. Asimismo, el Usuario será responsable de
                    asegurarse de que actualiza la App debidamente cuando así
                    proceda.
                  </p>
                  <p>
                    PagoDirecto aplicará las medidas de protección habituales
                    para la protección de datos del usuario, salvo que
                    PagoDirecto haya cometido negligencia grave, no será
                    responsable por los datos que hayan sido facilitados a un
                    tercero.
                  </p>
                  <p>
                    PagoDirecto no es una empresa recaudadora. PagoDirecto es un
                    tercero de buena fe, que brinda un derecho de uso de su
                    aplicación (App), y ejecuta sus distintas funciones en
                    nombre, representación y bajo instrucciones de sus usuarios.
                    PagoDirecto no será responsable por errores en las
                    instrucciones ingresadas en nuestra plataforma, ni por la
                    inejecución o retraso de una determinada recarga o pago por
                    causas imputables al usuario, beneficiario y/o instituciones
                    bancarias.
                  </p>
                </div>
                <div className="mb-4">
                  <h2
                    className="mb-0 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    6. Servicio de PagoDirecto.
                  </h2>
                  <p>
                    PagoDirecto fungirá de intermediario mediante la gestión de
                    un sistema para facilitar o agilizar los pagos
                    correspondientes al uso de determinados servicios que
                    utilice el Usuario.
                  </p>
                  <p>
                    PagoDirecto podrá ofrecer planes y servicios para la
                    adquisición de productos distintos al pago de
                    estacionamientos, mediante la publicación de dichos planes
                    en su página web. En caso de ser necesario, los Términos y
                    Condiciones de Uso de estos planes podrán modificados por
                    PagoDirecto.
                  </p>
                  <p>
                    PagoDirecto cobrará una cuota por uso del servicio descrito
                    en este contrato. Dicha cuota puede variar dependiendo al
                    estacionamiento afiliado que se ingrese y la misma podrá ser
                    aumentada unilateralmente.
                  </p>
                  <p>
                    PagoDirecto hará todo lo que se encuentre a su alcance para
                    proporcionar un servicio continuo e ininterrumpido. Sin
                    embargo, las limitaciones tecnológicas, así como el caso
                    fortuito o la fuerza mayor que impidan a PagoDirecto la
                    provisión del servicio con continuidad, no generarán en
                    cabeza del Usuario derecho a reclamación alguna.
                  </p>
                  <p>
                    PagoDirecto no tendrá ninguna responsabilidad en la fijación
                    del valor de la tarifa del servicio de estacionamiento
                    afiliado. La tarifa por el servicio de estacionamiento será
                    fijada exclusivamente por el establecimiento propietario u
                    operador del estacionamiento.
                  </p>
                </div>
                <div className="mb-4">
                  <h2
                    className="mb-3 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    7. Recargas en la aplicación (Monedero o Wallet).
                  </h2>

                  <div className="ml-3">
                    <div>
                      <h2
                        className="mb-0 btn  "
                        style={{ textAlign: "left", alignSelf: "flex-start" }}
                      >
                        7.1. Procedimiento, alcance y limitaciones de las
                        recargas.
                      </h2>
                      <p>
                        El usuario podrá recargar saldo en la aplicación
                        (wallet), tantas veces como desee y en atención a sus
                        necesidades y requerimientos.
                      </p>
                      <p>
                        El usuario podrá recargar saldo en la aplicación a
                        través de diferentes modalidades, entre ellas, por
                        transferencia bancaria, pago móvil interbancario (P2P),
                        pago por tarjeta de crédito (TDC) o recargas en puntos o
                        establecimientos autorizados.
                      </p>
                      <p>
                        PagoDirecto tendrá entre 24 horas y 48 horas para
                        habilitar y acreditar la recarga en la cuenta del
                        usuario.
                      </p>
                      <p>
                        Se emitirá una (1) sola factura mensual por usuario, por
                        el total consumido durante el mes calendario.
                      </p>
                      <p>
                        El usuario podrá imprimir su factura, que llegara a su
                        correo en un archivo digital previa solicitud en la app.
                      </p>
                      <p>
                        El Usuario entiende y acepta que PagoDirecto podrá
                        definir parámetros e incluir limitaciones con respecto a
                        los montos a cargar en la aplicación, con el fin de
                        evitar transacciones o movimientos que pudieran resultar
                        contrarios a la ley (tales como los relativos a
                        legitimación de capitales).
                      </p>
                      <p>
                        Los fondos que se acrediten en la aplicación podrían
                        permanecer temporalmente indisponibles cuando, a
                        exclusivo criterio de PagoDirecto, existan fuertes
                        sospechas de ilegalidad, fraude o cualquier otro acto
                        contrario a la ley.
                      </p>
                      <p>
                        El Usuario entiende y acepta que PagoDirecto no es y ni
                        actúa como entidad bancaria, por lo tanto solo provee un
                        servicio de pago, a través de un ticket digital, a un
                        tercero (estacionamiento). PagoDirecto no administra, ni
                        invierte ni es intermediario financiero de los saldos
                        acreditados en el monedero de los usuarios, y por lo
                        tanto los saldos disponibles y no utilizados por el
                        usuario no estarán sujetos al pago de ningún interés,
                        margen, rentabilidad, ganancia o beneficio económico
                        alguno a los usuarios, indistintamente del tiempo que
                        duren en el monedero.
                      </p>
                      <p>
                        El usuario conoce y entiende que puede ocurrir que el
                        saldo en su monedero sea negativo o existan cobros a
                        destiempo por parte de PagoDirecto. Esto puede suceder,
                        a título de ejemplo, cuando el usuario sale con su
                        vehículo del estacionamiento del centro comercial en
                        horario de pernocta (12:00 am a 6:00 am), lo cual podría
                        generar un cobro adicional que PagoDirecto solo lo podrá
                        conocer, una vez que la aplicación del usuario actualice
                        con el servidor de PagoDirecto.
                      </p>
                      <p>
                        En el caso de recargas con Tarjetas de Crédito, el
                        Usuario deberá proveer provisión de los detalles de su
                        tarjeta de crédito, por lo que comprende, entiende y
                        confirma que los detalles de la tarjeta de crédito
                        provistos son correctos y declara que es el titular de
                        dicha tarjeta y no hace uso de una tarjeta de crédito
                        obtenida de manera no autorizada, liberando de toda
                        responsabilidad a PagoDirecto por uso indebido o no
                        autorizado de la tarjeta de crédito.
                      </p>
                    </div>
                    <div className="mb-4">
                      <h2
                        className="mb-0 btn  "
                        style={{ textAlign: "left", alignSelf: "flex-start" }}
                      >
                        7.2. Exoneración de responsabilidad por recargas.
                      </h2>
                      <p>
                        PagoDirecto hará los mayores esfuerzos para la
                        actualización, mantenimiento y funcionamiento de su
                        plataforma. Sin embargo, no podrá garantizar la ausencia
                        de fallas técnicas, infalibilidad del servicio o que el
                        sistema se encuentre operativo en todo momento, dada la
                        alta incidencia de fallas ajenas a su voluntad.
                      </p>
                      <p>
                        El Usuario entiende que la disponibilidad y
                        funcionalidad de los servicios de recarga dependen de la
                        operatividad y seguridad de la plataforma, así como de
                        la señal de datos de su proveedor de servicio telefónico
                        o conexión a Wifi, así como de otras circunstancias de
                        las cuales PagoDirecto no tiene control y que por ende
                        no se le podrá imputar ninguna responsabilidad.
                      </p>
                      <p>
                        PagoDirecto no se hace responsable por fallas de
                        comunicación con Instituciones Bancarias al momento de
                        acreditación de recarga. El Usuario entiende que los
                        servicios de PagoDirecto dependen de que las plataformas
                        de las instituciones bancarias se mantengan bajo
                        parámetros de eficiente operatividad y confiabilidad.
                      </p>
                      <p>
                        En caso de fallas en la plataforma, PagoDirecto se
                        reserva el derecho de no ejecutar y/o anular operaciones
                        que se hayan solicitado.
                      </p>
                      <p>
                        El usuario no podrá imputarle responsabilidad alguna a
                        PagoDirecto, ni exigir resarcimiento alguno, en virtud
                        de perjuicios resultantes de las mencionadas
                        dificultades, así como por cualquier otra clase de
                        daños, incluyendo daños indirectos, especiales o
                        consecuentes que surjan o afecte al usuario.
                      </p>
                    </div>
                  </div>
                </div>

                <div>
                  <h2
                    className="mb-3 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    8. Asignación de Tickets Digitales y Planes al Usuario.
                  </h2>
                  <div className="ml-3 ">
                    <div>
                      <h2
                        className="mb-0 btn  "
                        style={{ textAlign: "left", alignSelf: "flex-start" }}
                      >
                        8.1. Asignación de los Tickets Digitales:
                      </h2>
                      <p>
                        Los Tickets Digitales serán asignados al Usuario al
                        momento de ingresar a un estacionamiento afiliado,
                        sincronizando automáticamente su teléfono inteligente
                        (mediante la App) con los módulos de ticket digital
                        (MTD), en las entradas y salidas de los estacionamientos
                        afiliados.
                      </p>
                      <p>
                        El servicio prestado por PagoDirecto al usuario tendrá
                        el costo equivalente a un porcentaje sobre el valor
                        total del ticket digital.
                      </p>
                      <p>
                        El Usuario es el único responsable del pago del ticket
                        digital en el comercio afiliado a PagoDirecto.
                      </p>
                      <p>
                        Queda prohibida todo tipo reventa, transferencia o
                        cesión de Tickets Digitales. El usuario será el único
                        responsable de revelar la información del Ticket Digital
                        a terceras personas ajenas al servicio.
                      </p>
                      <p>
                        El Usuario es el exclusivo responsable por las
                        instrucciones y medio de pago que utilice ante el
                        comercio afiliado y de sus consecuencias. PagoDirecto no
                        verificará la causa u obligación que originó la
                        instrucción de pago ni las demás circunstancias
                        relativas a la misma.
                      </p>
                      <p>
                        De toda operación procesada por el sistema PagoDirecto,
                        se generará un comprobante electrónico que utilizará la
                        empresa para dejar constancia de los pagos realizados
                        por el Usuario. El Usuario podrá imprimir o descargar
                        este comprobante para su respaldo y control en el
                        sistema PagoDirecto.
                      </p>
                      <p>
                        En caso de pérdida, daño, desperfecto, mal
                        funcionamiento, hurto o robo del teléfono inteligente
                        del usuario, PagoDirecto no facilitará un nuevo ticket
                        digital ni será responsable de las consecuencias de
                        tales hechos ante el estacionamiento afiliado.
                      </p>
                    </div>
                    <div>
                      <h2
                        className="mb-0 btn  "
                        style={{ textAlign: "left", alignSelf: "flex-start" }}
                      >
                        8.2. Planes ofrecidos al Usuario:
                      </h2>

                      <p>
                        Los planes que PagoDirecto llegue a ofrecer al Usuario
                        estarán publicados en su app y podrán ser modificados.
                      </p>
                    </div>

                    <div>
                      <h2
                        className="mb-0 btn  "
                        style={{ textAlign: "left", alignSelf: "flex-start" }}
                      >
                        8.3. Atención al Cliente:
                      </h2>

                      <p>
                        PagoDirecto hará su mejor esfuerzo por prestar un
                        servicio de calidad, continuo en todo caso, el Usuario
                        podrá realizar cualquier tipo de sugerencia, reclamo o
                        queja, a través del siguiente correo electrónico:
                        atencion@pagodirecto.com. PagoDirecto procurará dar
                        pronta respuesta y a solucionar cualquier inconveniente
                        generado al Usuario, siempre que dicho inconveniente sea
                        atribuible al servicio prestado por PagoDirecto. En caso
                        de que el inconveniente tenga que ver con el servicio
                        prestado por los Estacionamientos Afiliados o cualquier
                        tercero, PagoDirecto expresamente declara que, si bien
                        procurará canalizar las quejas y reclamos, no se hará en
                        ningún modo responsable de los mismos.
                      </p>
                    </div>
                  </div>
                </div>

                <div>
                  <h2
                    className="mb-0 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    9. Responsabilidad de PagoDirecto.
                  </h2>

                  <p>
                    PagoDirecto no realizará devolución alguna y no se hará
                    responsable en los siguientes casos:
                  </p>
                  <div className="ml-3">
                    {" "}
                    <p>
                      <span className="" style={{ fontWeight: "bold" }}>
                        1.
                      </span>{" "}
                      Si se presentan inconsistencias o retrasos en la
                      prestación del servicio de estacionamiento. El Usuario
                      reconoce y acepta que al realizar transacciones con los
                      Estacionamientos Afiliados, lo hace por su propia
                      voluntad, prestando su consentimiento libremente y bajo su
                      propio riesgo y responsabilidad. En caso de que algún
                      Usuario o tercero inicie cualquier tipo de reclamo o
                      acciones legales contra los Estacionamientos Afiliados, el
                      Usuario exime de toda responsabilidad a PagoDirecto y a
                      sus directores, gerentes, empleados, agentes, operarios,
                      representantes y apoderados.
                    </p>
                    <p>
                      <span className="" style={{ fontWeight: "bold" }}>
                        2.
                      </span>{" "}
                      Por pérdidas, gastos o daños ocasionados por el uso
                      inadecuado o negligente de los tickets digitales o de la
                      App.
                    </p>
                    <p>
                      <span className="" style={{ fontWeight: "bold" }}>
                        3.
                      </span>
                      Por daños causados por fuerza mayor, desórdenes públicos,
                      guerras y desastres naturales u otros hechos no
                      atribuibles a la empresa o que traspasen el control de la
                      misma.
                    </p>
                    <p>
                      <span className="" style={{ fontWeight: "bold" }}>
                        4.
                      </span>{" "}
                      Por fallas en el sistema web o App de PagoDirecto debido a
                      inconvenientes con los proveedores de Internet o a fallas
                      mayores que impliquen desperfectos, retrasos o caída de
                      internet a nivel local, regional o nacional.
                    </p>
                    <p>
                      <span className="" style={{ fontWeight: "bold" }}>
                        5.
                      </span>{" "}
                      Por rechazo de los tickets digitales por parte de alguno
                      de los Estacionamientos Afiliados sin causas debidamente
                      justificadas.
                    </p>
                    <p>
                      <span className="" style={{ fontWeight: "bold" }}>
                        6.
                      </span>{" "}
                      En el caso de que el Usuario no hiciere uso del ticket
                      digital de acuerdo con estos Términos y Condiciones.
                    </p>
                    <p>
                      <span className="" style={{ fontWeight: "bold" }}>
                        7.
                      </span>{" "}
                      Por quiebra, insolvencia o cierre temporal o permanente de
                      los Estacionamientos Afiliados o por error o defecto en
                      una función técnica que indique el importe de la tarifa de
                      estacionamiento;
                    </p>
                    <p>
                      <span className="" style={{ fontWeight: "bold" }}>
                        8.
                      </span>
                      Por extravío, daños, hurtos o robo del teléfono
                      inteligente del usuario, de su vehículo automotor y/o
                      accesorios, de los medios de pago que utilice tales como
                      tarjetas de débito o de crédito, ni por el origen de sus
                      fondos.
                    </p>
                    <p>
                      <span className="" style={{ fontWeight: "bold" }}>
                        9.
                      </span>
                      Por error o funcionamiento deficientes en relación con el
                      teléfono inteligente, teléfono móvil u otro equipo del
                      Usuario.
                    </p>
                    <p>
                      <span className="" style={{ fontWeight: "bold" }}>
                        10.
                      </span>{" "}
                      Por fallas mecánicas, eléctricas o cualquier otra índole,
                      de la barrera que da acceso o salida del estacionamiento.
                      PagoDirecto no es responsable del mantenimiento ni
                      operación de la barrera del estacionamiento.
                    </p>
                    <p>
                      <span className="" style={{ fontWeight: "bold" }}>
                        11.
                      </span>{" "}
                      Por cualquier otra causa que sea ajena a PagoDirecto o
                      esté fuera del control de PagoDirecto.
                    </p>
                  </div>

                  <p>
                    Si el Usuario se viere afectado por un hecho atribuible a un
                    fallo o error de PagoDirecto o de su sistema, ésta, previa
                    comprobación, se hará responsable únicamente de la pérdida
                    que sufra el Usuario hasta por el monto máximo
                    correspondiente al valor del ticket digital.
                  </p>
                  <p>
                    Si por cualquier motivo el Usuario no está satisfecho con el
                    producto o con la gestión del servicio ofrecido por
                    PagoDirecto, o ha habido un error en las transacciones
                    electrónicas realizadas por el Usuario, el mismo deberá
                    notificarlo a PagoDirecto a través del correo electrónico
                    atencion@pagodireto.com, para que la empresa investigue las
                    circunstancias.
                  </p>
                  <p>
                    El Usuario deberá asimismo indemnizar y mantener indemne a
                    PagoDirecto frente a las reclamaciones de terceros que se
                    efectúen contra PagoDirecto que sean consecuencia directa o
                    indirecta de la utilización por el usuario de algún
                    servicio.
                  </p>
                  <p>
                    Para garantizar el mejor nivel de servicio posible,
                    PagoDirecto se reserva el derecho a interrumpir el servicio
                    para labores de mantenimiento, actualizaciones del sistema o
                    cualesquiera otros cambios, informando adecuadamente a los
                    usuarios.
                  </p>
                </div>

                <div>
                  <h2
                    className="mb-0 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    10. Exclusión de Garantías y de Responsabilidad de
                    PagoDirecto.
                  </h2>

                  <p>
                    PagoDirecto no asume responsabilidad alguna por el
                    contenido, funcionalidad, utilidad, fiabilidad o exactitud
                    de las páginas web o aplicaciones a las que pueda conectarse
                    el Cliente a través de los vínculos disponibles en Internet.
                  </p>
                  <p>
                    PagoDirecto no puede visualizar previamente ningún contenido
                    incluido en cualquier página web, tampoco puede editar,
                    suprimir, modificar, eliminar, controlar, revisar ni
                    restringir el acceso a ningún contenido incluido en
                    cualquier página web previamente a su transmisión al
                    Cliente, por lo que PagoDirecto no garantiza que los
                    contenidos de las páginas web a las que se acceda a través
                    de los servicios prestados por PagoDirecto vayan a estar
                    constantemente actualizados, que vayan a ser conformes a la
                    ley, a la moral, buena fe y/o a las buenas costumbres, su
                    exactitud, licitud, veracidad y/o exhaustividad, o que vayan
                    a ser útiles para el Cliente o que no vayan a contener
                    ninguna clase de error, por todo lo cual, PagoDirecto no se
                    responsabiliza de ningún daño y/o perjuicio y/o beneficios
                    dejados de obtener por el Cliente o cualquier otro tercero
                    que no sean imputables única y exclusivamente a PagoDirecto,
                    así como tampoco cuando el Cliente o las personas de las que
                    éste deba responder sean las culpables de tales daños y
                    perjuicios, o bien cuando el Cliente se vea perjudicado a
                    consecuencia de cualquier contenido, información, opinión,
                    manifestación y/o dato al que el Cliente o cualquier otro
                    tercero acceda o adquiera por medio de cualquier página web
                    a la que se haya accedido a través de los servicios
                    prestados por PagoDirecto.
                  </p>
                  <p>
                    A pesar de que realizará todos los esfuerzos técnicos y
                    organizativos necesarios, PagoDirecto no garantiza que los
                    servicios prestados vayan a funcionar con seguridad
                    absoluta, constante, fiable y permanentemente, sin retrasos
                    o interrupciones, por lo que PagoDirecto no se
                    responsabiliza por ningún daño y/o perjuicio y/o beneficios
                    dejados de obtener por el Cliente o cualquier otro tercero
                    que se vea perjudicado a consecuencia de los mismos así como
                    tampoco por cualquier circunstancia de fuerza mayor, caso
                    fortuito, fallo o error de las líneas de comunicación, o de
                    la prestación defectuosa o fallo de la red de acceso a
                    Internet, siempre y cuando dichos fallos no sean imputables
                    a PagoDirecto.
                  </p>
                  <p>
                    PagoDirecto no será responsable de ningún daño o perjuicio
                    ocasionado a ningún Cliente o a terceros, ni por ganancias
                    dejadas de obtener por ningún Cliente y/o tercero, causados
                    directa o indirectamente de la utilización y/o acceso y/o
                    conexión a los servicios prestados por PagoDirecto y/o a
                    páginas web a ellos enlazadas, no siendo por tanto
                    responsable por ningún daño y/o perjuicio ocasionado a
                    ningún Cliente y/o a terceros, ni por ganancias dejadas de
                    obtener por ningún Cliente y/o terceros a causa del
                    funcionamiento incorrecto, defectos, fallos y/o daños
                    causados, totales y/o parciales, a todo hardware, software
                    y/o programas informáticos pertenecientes al Cliente y/o
                    terceros, así como de la pérdida, alteración y/o daños,
                    totales y/o parciales sobre información contenida en
                    soportes magnéticos, discos, cintas, disquetes y otros
                    pertenecientes al Cliente y/o terceros, así como por la
                    introducción de virus informáticos o de variaciones o
                    alteraciones no deseadas sobre toda información, documentos,
                    ficheros, bases de datos, hardware y/o software
                    pertenecientes al Cliente y/o terceros, siempre y cuando
                    estos daños no fueran imputables a PagoDirecto.
                  </p>
                  <p>
                    El Cliente será responsable por todos los daños y/o
                    perjuicios que tanto por dolo como por negligencia o culpa
                    ocasione a PagoDirecto y en el cumplimiento del presente
                    contrato, específicamente, al contratar los servicios de
                    PagoDirecto el usuario acepta de forma expresa las
                    condiciones de acceso y uso vigentes en los estacionamientos
                    que vaya a utilizar, y entiende que estas normas son
                    dictadas exclusivamente por los estacionamientos. En ningún
                    caso, incluyendo sin limitación la negligencia, PagoDirecto
                    no será responsable de los daños y perjuicios de cualquier
                    tipo contra vehículos automotores, personas o propiedades,
                    incluyendo sin limitación cualquier daño directo, indirecto
                    o advertido expresamente, que surja de, o en relación con,
                    el acceso, o uso, del estacionamiento, siendo que en dicho
                    caso el usuario deberá dirigirse directamente contra los
                    propietarios o responsables de los estacionamientos.
                  </p>
                  <p>
                    PagoDirecto no será responsable por ningún daño o perjuicio
                    que haya podido causar al usuario cuando su actuación fuere
                    conforme a la buena fe.
                  </p>
                </div>

                <div>
                  <h2
                    className="mb-0 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    11. Devolución de saldos disponibles por recargas.
                  </h2>

                  <p>
                    En caso que el usuario quiera retirar los fondos disponibles
                    no utilizados, deberá escribir a soporte (atención al
                    cliente) expresando su solicitud y se le solicitaran los
                    requisitos necesarios para efectuar el reembolso en de las
                    siguientes 48 horas hábiles
                  </p>
                  <p>
                    El usuario aprueba que cualquier comisión bancaria que cause
                    la operación de devolución del saldo será debitada del saldo
                    disponible a ser devuelto.
                  </p>
                </div>

                <div>
                  <h2
                    className="mb-0 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    12. De la Obligación de Hacer Uso Debido y Apropiado de la
                    Aplicación PagoDirecto.
                  </h2>

                  <p>
                    El usuario deberá abstenerse de utilizar la aplicación y los
                    servicios prestados por PagoDirecto, con fines distintos
                    para los cuales fue diseñada o con fines ilícitos, en
                    contravención al Ordenamiento Jurídico vigente, así como a
                    lo establecido en los Términos y Condiciones aquí
                    transcritos, de tal forma que pueda resultar lesionados los
                    derechos e intereses de terceros, el orden público, así como
                    a la moral y buenas costumbres.
                  </p>
                  <p>
                    El usuario no deberá permitir que terceros utilicen su
                    aplicación, pues la misma es de carácter personal e
                    intransferible.
                  </p>
                  <p>
                    El usuario declara que conoce plenamente el origen y destino
                    de los fondos involucrados, en las transacciones realizadas
                    a través de su cuenta o wallet, por ende, el usuario es el
                    único responsable de las implicaciones legales que estás
                    puedan acarrear.
                  </p>
                  <p>
                    Es obligación del usuario, el uso adecuado del usuario y
                    contraseña, los cuales son de carácter personal e
                    intransferibles, como parte de la labor compartida en el
                    adecuado manejo y protección de los datos suministrados.
                    Cualquier uso indebido, delegación, revelación total o
                    parcial de estos datos por parte del usuario, producto del
                    inadecuado resguardo, negligencia, impericia o inobservancia
                    de las condiciones establecidas en estos Términos y
                    Condiciones, releva totalmente a PagoDirecto de cualquier
                    responsabilidad respecto de las transacciones que realicen
                    terceros no autorizados en la cuenta del usuario.
                  </p>
                  <p>
                    PagoDirecto hará lo suficientemente razonable y a su alcance
                    por establecer mecanismos para minimizar los riesgos
                    asociados a la Legitimación de Capitales, Financiamiento al
                    Terrorismo y Financiamiento a la Proliferación de Armas de
                    Destrucción Masiva LC/FT/FPADM. Por ello, el usuario deberá
                    acatar las normas establecidas en esa materia en la
                    Legislación venezolana. En consecuencia, el usuario se
                    obliga a no usar su cuenta o la aplicación para realizar
                    operaciones que enmascaren, oculten o disimulen el origen de
                    actividades de origen ilícitas. PagoDirecto podrá, amparado
                    en las regulaciones en la materia, restringir, bloquear o
                    denegar tales operaciones y reportarlas a las autoridades
                    competentes, así como también podrá suministrar información
                    relacionada con la cuenta del usuario a las autoridades
                    legales, que lo soliciten en función de alguna investigación
                    relacionada con la presunta comisión de delitos asociados a
                    la Legitimación de Capitales, Financiamiento al Terrorismo y
                    Financiamiento a la Proliferación de Armas de Destrucción
                    Masiva u otros.
                  </p>
                </div>

                <div>
                  <h2
                    className="mb-0 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    13. Cancelación de Cuenta por Mal Uso.
                  </h2>

                  <p>
                    PagoDirecto se reserva el derecho de cancelar cualquier
                    cuenta en caso de que la misma sea utilizada en
                    contravención a los presentes términos de uso y condiciones
                    del servicio. La decisión de cancelar una cuenta de usuario
                    se hará a la entera discreción de PagoDirecto y tal decisión
                    será comunicada a la brevedad posible al usuario.
                  </p>
                </div>

                <div>
                  <h2
                    className="mb-0 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    14. Protección de Datos y Confidencialidad.
                  </h2>

                  <p>
                    PagoDirecto almacena los datos provistos por el Usuario al
                    momento de registro, los cuales serán tratados con carácter
                    confidencial. Sin embargo, PagoDirecto podrá suministrarlos
                    cuando sean requeridos por órganos del Estado. Al crear una
                    cuenta en su sistema, PagoDirecto se compromete a no vender,
                    alquilar o compartir con terceros la información
                    suministrada por el Usuario.
                  </p>
                  <p>
                    Asimismo, PagoDirecto podrá confirmar los datos personales
                    suministrados acudiendo a entidades públicas, compañías
                    especializadas o centrales de riesgo, para lo cual el
                    Usuario la autoriza expresamente.
                  </p>
                  <p>
                    PagoDirecto no se hace responsable bajo ningún concepto por
                    daños y perjuicios de cualquier naturaleza que puedan
                    suscitarse por la errada, falta de veracidad, vigencia y/o
                    autenticidad de la información suministrada. PagoDirecto
                    tampoco se hace responsable por los daños y perjuicios de
                    cualquier naturaleza que puedan deberse a la suplantación de
                    la personalidad de un tercero efectuada por un Usuario en
                    cualquier clase de comunicación o transacción realizada a
                    través del portal y servicio ofrecido por PagoDirecto.
                  </p>
                  <p>
                    PagoDirecto recoge y almacena automáticamente información
                    acerca de las actividades del Usuario y visitantes dentro de
                    su sitio web. Esta información incluye la dirección URL de
                    la que provienen, a qué URL acceden seguidamente, qué
                    navegador están usando y sus direcciones IP. También las
                    páginas visitadas, las búsquedas realizadas, las
                    publicaciones y compras, entre otra información, podrá ser
                    almacenada y retenida. PagoDirecto no se hace responsable
                    por interceptaciones ilegales o violación de sus sistemas o
                    bases de datos por parte de personas no autorizadas y
                    tampoco se hace responsable por la indebida utilización de
                    la información obtenida por esos medios.
                  </p>
                  <p>
                    PagoDirecto podrá compartir con terceros la información que
                    reposa en sus sistemas y sitio web correspondiente a sus
                    usuarios.
                  </p>
                  <p>
                    PagoDirecto se reserva el derecho de solicitar documentos
                    adicionales en caso de que se considere necesario verificar
                    más información del Usuario registrado en su plataforma web.
                    De igual forma, se reserva el derecho de suspender temporal
                    o definitivamente a aquel Usuario cuyos datos reflejen
                    operaciones sospechosas.
                  </p>
                  <p>
                    PagoDirecto tendrá el derecho a ceder total o parcialmente
                    sus derechos u obligaciones derivados del presente Contrato
                    sin el consentimiento del usuario. Además, PagoDirecto
                    tendrá derecho a utilizar subcontratistas para cumplir con
                    sus obligaciones conforme a los presentes Términos y
                    Condiciones.
                  </p>
                </div>

                <div>
                  <h2
                    className="mb-0 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    15. Prevalencia de los Términos y Condiciones de Uso.
                  </h2>

                  <p>
                    Si alguna parte o disposición de estos Términos y
                    Condiciones de Uso fuese imposible de cumplir o estuviese en
                    conflicto con la legislación vigente en el ámbito de su
                    aplicación, ello no afectará el cumplimiento de las
                    restantes disposiciones ni la validez de las mismas. Si se
                    determinase que cualquiera de las indemnizaciones y/o
                    responsabilidades contenidas en este documento, excediese
                    los importes monetarios permitidos por la legislación
                    correspondiente, se acuerda expresamente que dichas
                    disposiciones se modificarán automáticamente y sin recurso
                    alguno para ajustarse a los límites permitidos por la ley.
                  </p>
                </div>
                <div>
                  <h2
                    className="mb-0 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    16. Modificación de los Términos y Condiciones de Uso.
                  </h2>

                  <p>
                    Las eventuales modificaciones de estos Términos y
                    Condiciones de Uso se publicarán, en cada oportunidad que
                    ocurra, en la app de PagoDirecto. La empresa se reserva el
                    derecho a realizar cambios en dichos Términos y Condiciones
                    de Uso, sin previo aviso y siempre que lo considere
                    conveniente o necesario.
                  </p>
                  <p>
                    La actualización o modificación de los Términos y
                    Condiciones serán notificados al usuario a través de la
                    propia aplicación. Una vez publicadas las modificaciones o
                    actualizaciones realizadas a los términos y condiciones aquí
                    establecidos; en el caso de que el usuario dé continuidad al
                    uso de los servicios ofrecidos por PagoDirecto, una vez
                    notificado, se entenderán aceptados por el mismo.
                  </p>
                </div>
                <div>
                  <h2
                    className="mb-0 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    17. Legislación aplicable, domicilio y jurisdicción.
                  </h2>

                  <p>
                    Estos Términos y Condiciones de Uso se regirán y se
                    interpretarán de conformidad con las leyes de la República
                    Bolivariana de Venezuela. Las partes eligen como domicilio
                    especial la ciudad de Caracas, a la jurisdicción de cuyos
                    tribunales acuerdan someterse.
                  </p>
                  <p>
                    Las notificaciones que deba realizar PagoDirecto al Usuario
                    podrán efectuarse a través de su cuenta de correo
                    electrónico depositada durante el registro o directamente a
                    su cuenta en la plataforma de PagoDirecto.
                  </p>
                  <p>
                    Para todos los efectos legales y contractuales, el domicilio
                    de PagoDirecto es la ciudad de Caracas.
                  </p>
                  <p>
                    El correo electrónico de PagoDirecto es:
                    atencion@pagodirecto.com
                  </p>
                </div>
                <div>
                  <h2
                    className="mb-0 btn  "
                    style={{ textAlign: "left", alignSelf: "flex-start" }}
                  >
                    18. Entrada en vigencia.
                  </h2>

                  <p>
                    Fecha de entrada en vigor: el día Primero (01) de diciembre
                    del año dos mil veintitrés (2023). Queda sin efecto toda
                    declaración de Términos y Condiciones emitida con fecha
                    anterior.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div style={{ height: "500px" }}></div>
        </div>
      </section>
    </>
  );
};

export default Main;
