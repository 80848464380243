import { Link } from "react-router-dom";
import React from "react";
import frame from "../../../assets/images/case-web.png";
import screen from "../../../assets/images/about-screen.png";
import img1 from "../../../assets/images/download.png";
import img2 from "../../../assets/images/followers.png";
import img3 from "../../../assets/images/reviews.png";
import img4 from "../../../assets/images/countries.png";

const Main = ({ video, dark }) => {
  return (
    <>
      <section className="row_am about_app_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="about_img"
                data-aos="fade-in"
                data-aos-duration="1500"
              >
                <img
                  className="moving_position_animatin"
                  src={frame}
                  alt="our_tech"
                />
              </div>
              <Link
                to="/contact"
                className="btn puprple_btn left-link"
                data-aos="fade-in"
                data-aos-duration="1500"
              >
                ¡Contáctanos!
              </Link>
            </div>
            <div className="col-lg-6">
              <div className="about_text">
                <div
                  className="section_title"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-delay="100"
                >
                  <h2 className="sub-title-mb our_tech_title" style={{textAlign: 'left'}}>
                    <span>Nuestra Tecnología</span>
                  </h2>
                  <p>
                    Desarrollamos un dispositivo multifuncional de conexión
                    Bluetooth que permite que nuestros usuarios puedan tener una
                    interacción distinta con cualquier tipo de comercio en
                    cuestión de micro segundos. Este hardware lo implementamos
                    en diferentes escenarios como estacionamientos, peajes,
                    parquímetros o comercios. Con el propósito de que la
                    interacción comience a través de esa conexión y el usuario
                    pueda tener flujos no transaccionales y transaccionales a
                    través de su teléfono inteligente. De esta manera logramos
                    la digitalización de métodos analógicos P2C y C2P.
                  </p>
                </div>

                <Link
                  to="/contact"
                  className="btn puprple_btn right-link "
                  data-aos="fade-in"
                  data-aos-duration="1500"
                  style={{ marginTop: "60px" }}
                >
                  ¡Contáctanos!
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;
