import OwlCarousel from "react-owl-carousel";
import React from "react";
import img1 from "../../../assets/images/alcaldiaccs.svg";
import img2 from "../../../assets/images/bancamiga1.svg";
import img3 from "../../../assets/images/ccct1.svg";
import img4 from "../../../assets/images/fondovalores.svg";
import img5 from "../../../assets/images/tantalo.svg";
import img6 from "../../../assets/images/mesaDeTrabajo.svg";

const Main = () => {
  const company_slider = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    smartSpeed: 1500,
    dots: true,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };

  return (
    <>
      <section className="row_am trusted_section">
        <div className="container">
          <div
            className="section_title"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="100"
          >
            <h2 className="sub-title-mb">Aliados comerciales</h2>
            <p>
              Empresas y entes gubernamentales que han confiado en nuestros
              productos.
            </p>
          </div>
          <div className="company_logos">
            <OwlCarousel
              id="company_slider"
              {...company_slider}
              className="owl-carousel owl-theme owl-loaded owl-drag"
            >
              <div className="item">
                <div className="logo">
                  <img src={img1} alt="bancamiga" />
                </div>
              </div>
              <div className="item">
                <div className="logo">
                  <img src={img2} alt="t" />
                </div>
              </div>
              <div className="item">
                <div className="logo">
                  <img src={img3} alt="alcaldia de caracas" />
                </div>
              </div>
              <div className="item">
                <div className="logo">
                  <img src={img4} alt="Tántalo" />
                </div>
              </div>
              <div className="item">
                <div className="logo">
                  <img src={img5} alt="Fondo valores" />
                </div>
              </div>
              <div className="item">
                <div className="logo">
                  <img src={img6} alt="Fondo valores" />
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;
