import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BGImg from "../../../assets/images/bread_crumb_bg.png";
import BGImg1 from "../../../assets/images/bread_crumb_bg_one.png";
import BGImg2 from "../../../assets/images/bread_crumb_bg_two.png";

const Main = ({ brdcum, bgimg }) => {
  const [parkingLots, setParkingLots] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL_API}/commerces/list?category=${selectedOption}`)
      .then((res) => res.json())
      .then((data) => {
        data = data.commerces;
        setParkingLots(data);
      });
  }, [selectedOption]);

  return (
    <>
      {brdcum.b1 && (
        <div className="bred_crumb">
          <div className="container">
            <span className="banner_shape1">
              {" "}
              <img src="assets/images/banner-shape1.png" alt="image" />{" "}
            </span>
            <span className="banner_shape2">
              {" "}
              <img src="assets/images/banner-shape2.png" alt="image" />{" "}
            </span>
            <span className="banner_shape3">
              {" "}
              <img src="assets/images/banner-shape3.png" alt="image" />{" "}
            </span>

            <div className="bred_text">
              <h1 style={{ color: "#ce4c73" }}>Dónde estamos</h1>

              <div className="search_bar">
                <form action="">
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={(e) => setSelectedOption(e.target.value)}
                      value={selectedOption}
                    >
                      <option value="">Todas nuestras ubicaciones</option>
                      <option value="estacionamiento">Estacionamientos</option>
                      <option value="peaje">Peajes</option>
                    </select>

                    <button className="btn" onClick={(e) => e.preventDefault()}>
                      <i className="icofont-search-1"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      <section className="row_am latest_story blog_list_story" id="blog">
        <div className="container">
          <div className="row">
            {parkingLots.map((parkingLot) => {
              return (
                <div className="col-md-3" key={parkingLot.commerce_id}>
                  <div
                    className="story_box"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    <div className="story_img">
                      <img
                        src={`${process.env.REACT_APP_URL_IMG}/${parkingLot.commerce_id}`}
                        alt="imagse"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "assets/images/story01.png";
                        }}
                        // ! aca se ajusta el tamaño de las fotos
                        // style={{ width: "100%", height: "280px" }}
                      />
                      {/* <span>45 min ago</span> */}
                    </div>
                    <div className="story_text" style={{ textAlign: "center" }}>
                      <h3 style={{ color: "black", fontSize: "25px" }}>
                        {parkingLot.commerce_name}
                      </h3>
                      <p
                        style={{
                          fontWeight: "700",
                          color: "#8A8A8A",
                          marginTop: "-15px",
                        }}
                      >
                        {parkingLot.commerce_category}
                      </p>

                      {parkingLot?.parking_mode?.parking?.name && (
                        <>
                          <p>
                            {parkingLot?.parking_mode?.parking?.name}{" "}
                            <span style={{ fontWeight: "bold" }}>
                              Bs.{" "}
                              {parkingLot?.parking_mode?.parking?.amount.toFixed(
                                2
                              )}
                            </span>
                          </p>
                          <p style={{ marginTop: "-20px" }}>
                            Servicio PagoDirecto{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {parkingLot?.parking_mode?.pd_fee?.amount.toFixed(
                                2
                              )}
                            </span>
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* <div className="pagination_block">
                    <ul>
                        <li><Link to="#" className="prev"><i className="icofont-arrow-left"></i> Prev</Link></li>
                        <li><Link to="#">1</Link></li>
                        <li><Link to="#" className="active">2</Link></li>
                        <li><Link to="#">3</Link></li>
                        <li><Link to="#">4</Link></li>
                        <li><Link to="#">5</Link></li>
                        <li><Link to="#">6</Link></li>
                        <li><Link to="#" className="next">Next <i className="icofont-arrow-right"></i></Link></li>
                    </ul>
                </div> */}
        </div>
      </section>
    </>
  );
};

export default Main;
