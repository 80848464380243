import React, { useState, useEffect } from "react";
import Trusted from "../HomeMain/Trusted/Main";
import { Link, useLocation } from "react-router-dom";
import Bredcrumb from "../Bredcrumb/Main";
import BGImg from "../../assets/images/bread_crumb_bg.png";
import BGImg1 from "../../assets/images/bread_crumb_bg_one.png";
import BGImg2 from "../../assets/images/bread_crumb_bg_two.png";

import Home from "../Home/Main";

const Main = ( ) => {

  useEffect(() => {
    // if device is android redirect to google play store
    if (navigator.userAgent.match(/Android/i)) {
      window.location.replace(
        "https://play.google.com/store/apps/details?id=com.paytech.pagodirecto"
      );
    }
    // if device is ios redirect to app store
    if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      window.location.replace(
        "https://apps.apple.com/ve/app/pago-directo/id1440607618"
      );
    }
  }, []);

  const [homepage, sethomepage] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/sign-in" || location.pathname === "/sign-up") {
      sethomepage(false);
    } else {
      sethomepage(true);
    }
  }, [location]);

  const [footerpage, setfooterpage] = useState(false);
  useEffect(() => {
    if (location.pathname === "/sign-in" || location.pathname === "/sign-up") {
      setfooterpage(false);
    } else {
      setfooterpage(true);
    }
  }, [location]);

  useEffect(() => {
    if (localStorage.getItem("navbar") === "darkhome") {
      setfooter({ f2: true });
      setnavbar({ n3: true });
      setbrdcum({ b2: true });
    } else if (localStorage.getItem("navbar") === "home") {
      setfooter({ f1: true });
      setnavbar({ n2: true });
      setbrdcum({ b1: true });
    } else if (localStorage.getItem("navbar") === "homewave") {
      setfooter({ f5: true });
      setbrdcum({ b1: true });
      setnavbar({ n4: true });
    } else if (localStorage.getItem("navbar") === "homevideo") {
      setfooter({ f1: true });
      setnavbar({ n6: true });
      setbrdcum({ b3: true });
    } else if (localStorage.getItem("navbar") === "homevideotwo") {
      setfooter({ f4: true });
      setnavbar({ n4: true });
      setbrdcum({ b4: true });
    } else {
      setfooter({ f1: true });
      setnavbar({ n2: true });
    }
  }, []);

  const [footer, setfooter] = useState({ f1: true });
  const [navbar, setnavbar] = useState({ n1: true });
  const [brdcum, setbrdcum] = useState({ n1: true });

  return (
    <Home setfooter={setfooter} setnavbar={setnavbar} setbrdcum={setbrdcum} />
  );
};

export default Main;
