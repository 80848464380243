import { HashLink as Link } from "react-router-hash-link";
import React, { useEffect } from "react";
import AOS from "aos";
import Trusted from "../HomeMain/Trusted/Main";
import Features from "../HomeMain/Features/Main";
import AboutApp from "../HomeMain/AboutApp/Main";
import Work from "../HomeMain/Work/Main";
import Testimonial from "../HomeMain/Testimonial/Main";
// import Pricing from "../HomeMain/Pricing/Main";
import Faq from "../HomeMain/Faq/Main";
// import Interface from "../HomeMain/Interface/Main";
// import Download from "../HomeMain/Download/Main";
// import Story from "../HomeMain/Story/Main";
import Wallet from "../HomeMain/Design/Main";
import ParkingLot from "../Blog/Blog/";

const Main = ({ setfooter, setnavbar, setbrdcum }) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  useEffect(() => {
    setfooter({ f5: false });
    setbrdcum({ b1: true });
    setnavbar({ n4: true });
    localStorage.setItem("navbar", "homewave");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   const frmae_slider = {
  //     loop: true,
  //     margin: 10,
  //     nav: false,
  //     autoplay: true,
  //     smartSpeed: 1500,
  //     dots: true,
  //     responsive: {
  //       0: {
  //         items: 1,
  //       },
  //       600: {
  //         items: 1,
  //       },
  //       1000: {
  //         items: 1,
  //       },
  //     },
  //   };

  return (
    <>
      <section className="banner_section home-wave">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 col-md-12"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <div className="banner_text">
                <h1>
                  Cambia tu tiempo, <br /> <span>por momentos</span>{" "}
                </h1>
                <p>
                  Antes, durante y después del pago. Todo hazlo directo con
                  PagoDirecto
                </p>
              </div>
              <ul className="app_btn">
                <li>
                  <a href="https://apps.apple.com/ve/app/pago-directo/id1440607618">
                    <img
                      className="blue_img"
                      src="assets/images/appstore_white.svg"
                      alt="images"
                      style={{ width: "150px" }}
                    />
                    <img
                      className="white_img"
                      src="assets/images/appstore_white.svg"
                      alt="images"
                      style={{ width: "150px" }}
                    />
                  </a>
                </li>
                <li>
                  <a href="https://play.google.com/store/apps/details?id=com.paytech.pagodirecto">
                    <img
                      className="blue_img"
                      src="assets/images/googleplay_white.svg"
                      alt="images"
                      style={{ width: "150px" }}
                    />
                    <img
                      className="white_img"
                      src="assets/images/googleplay_white.svg"
                      alt="images"
                      style={{ width: "150px" }}
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div
              className="col-lg-6 col-md-12"
              data-aos="fade-in"
              data-aos-duration="1500"
            >
              <div className="banner_image">
                <img
                  className="moving_animation"
                  src="assets/images/banner-image.svg"
                  alt="home_image"
                />
              </div>

              <ul className="app_btn second_app_btn">
                <li>
                  <a href="https://apps.apple.com/ve/app/pago-directo/id1440607618">
                    <img
                      className="blue_img"
                      src="assets/images/appstore_white.svg"
                      alt="images"
                      style={{ width: "150px", height: "50px"}}
                    />
                    <img
                      className="white_img"
                      src="assets/images/appstore_white.svg"
                      alt="images"
                      style={{ width: "150px" }}
                    />
                  </a>
                </li>
                <li>
                  <a href="https://play.google.com/store/apps/details?id=com.paytech.pagodirecto">
                    <img
                      className="blue_img"
                      src="assets/images/googleplay_white.svg"
                      alt="images"
                      style={{ width: "150px", height: "50px"}}
                    />
                    <img
                      className="white_img"
                      src="assets/images/googleplay_white.svg"
                      alt="images"
                      style={{ width: "150px" }}
                    />
                  </a>
                </li>
              </ul>
            </div>

            <span className="banner_shape1">
              {" "}
              <img src="assets/images/banner-shape1.png" alt="shape1" />{" "}
            </span>
            <span className="banner_shape2">
              {" "}
              <img src="assets/images/banner-shape2.png" alt="shape2" />{" "}
            </span>
            <span className="banner_shape3">
              {" "}
              <img src="assets/images/banner-shape3.png" alt="shape3" />{" "}
            </span>
          </div>
        </div>
        <div className="home-wave-bottom">
          <svg
            className="waves"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shape-rendering="auto"
          >
            <defs>
              <path
                id="gentle-wave"
                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              />
            </defs>
            <g className="parallax">
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="0"
                fill="rgba(255,255,255,0.7"
              />
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="3"
                fill="rgba(255,255,255,0.5)"
              />
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="5"
                fill="rgba(255,255,255,0.3)"
              />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#f6f4fe" />
            </g>
          </svg>
        </div>
      </section>

      <Trusted />
      <Features />
      <Work />
      <Wallet />
      <AboutApp />
      <Testimonial />
      <ParkingLot />
      <Faq />

      {/* <Trusted />
        <Features />
        <AboutApp />
        <Design />
        <Work />
        <Testimonial />
        <Pricing />
        <Faq/>
        <Interface/>
        <Download />
        <Story/> */}
    </>
  );
};

export default Main;
