import React from "react";
import img1 from "../../../assets/images/icon-est.svg";
import img2 from "../../../assets/images/icon-serv.svg";
import img3 from "../../../assets/images/icon-pea.svg";
import img4 from "../../../assets/images/icon-par.svg";
import img5 from "../../../assets/images/1telefonos.svg";

const Main = ({ video }) => {
  return (
    <>
      {video ? (
        <section
          className="row_am features_section video-features"
          id="features"
        >
          <div className="container">
            <div
              className="section_title"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="100"
            >
              <h2>
                <span>Features</span> that makes app different!
              </h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typese
                tting <br /> indus orem Ipsum has beenthe standard dummy.
              </p>
            </div>
            <div className="feature_detail">
              <div className="left_data feature_box">
                <div
                  className="data_block"
                  data-aos="fade-right"
                  data-aos-duration="1500"
                >
                  <div className="icon">
                    <img src="assets/images/secure.png" alt="image" />
                  </div>
                  <div className="text">
                    <h4>Secure data</h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and type
                      setting indus ideas.
                    </p>
                  </div>
                </div>
                <div
                  className="data_block"
                  data-aos="fade-right"
                  data-aos-duration="1500"
                >
                  <div className="icon">
                    <img src="assets/images/abt_functional.png" alt="image" />
                  </div>
                  <div className="text">
                    <h4>Fully functional</h4>
                    <p>
                      Simply dummy text of the printing and typesetting indus
                      lorem Ipsum is dummy.
                    </p>
                  </div>
                </div>
              </div>
              <div className="right_data feature_box">
                <div
                  className="data_block"
                  data-aos="fade-left"
                  data-aos-duration="1500"
                >
                  <div className="icon">
                    <img src="assets/images/communication.png" alt="image" />
                  </div>
                  <div className="text">
                    <h4>Live chat</h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and type
                      setting indus ideas.
                    </p>
                  </div>
                </div>
                <div
                  className="data_block"
                  data-aos="fade-left"
                  data-aos-duration="1500"
                >
                  <div className="icon">
                    <img src="assets/images/abt_support.png" alt="image" />
                  </div>
                  <div className="text">
                    <h4>24-7 Support</h4>
                    <p>
                      Simply dummy text of the printing and typesetting indus
                      lorem Ipsum is dummy.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="feature_img"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="100"
              >
                <img src="assets/images/features_frame.png" alt="image" />
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="row_am features_section" id="features">
          <div className="container">
            <div
              className="section_title"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="100"
            >
              <h2 className="sub-title-mb" style={{ textAlign: "center" }}>
                <span>Productos</span>
              </h2>
              <p className="center-text-mobile">
                Nuestro propósito es agilizar, a través de la digitalización,
                flujos de interacción entre usuario y comercio en el antes,
                durante y después del pago.
              </p>
              <p className="center-text-mobile">
                Conoce las funciones que hemos desarrollado para que cambies tu
                tiempo por momentos
              </p>
            </div>

            <div className="feature_detail">
              <div className="left_data feature_box">
                <div
                  className="data_block"
                  data-aos="fade-right"
                  data-aos-duration="1500"
                >
                  <div className="icon">
                    <img src={img1} alt="estacionamiento"  style={{height: '130px'}}/>
                  </div>
                  <div className="text">
                    <h4>Estacionamiento</h4>
                    <p>
                      Digitalizamos el flujo tradicional de los estacionamientos
                      con nuestro sistema bluetooth permitiendo entrar, pagar y
                      salir usando sólo el teléfono.
                    </p>
                  </div>
                </div>
                <div
                  className="data_block"
                  data-aos="fade-right"
                  data-aos-duration="1500"
                >
                  <div className="icon">
                    <img src={img2} alt="servicios" style={{height: '130px'}} />
                  </div>
                  <div className="text">
                    <h4>Servicios</h4>
                    <p>
                      Paga todos tus básicos en segundos, desde servicios
                      telefónicos, internet y televisión.
                    </p>
                  </div>
                </div>
              </div>
              <div className="right_data feature_box">
                <div
                  className="data_block"
                  data-aos="fade-left"
                  data-aos-duration="1500"
                >
                  <div className="icon" >
                    <img src={img3} alt="peajes" style={{height: '130px'}} />
                  </div>
                  <div className="text">
                    <h4>Peajes</h4>
                    <p>
                      Un método de pago único en el mundo. Ahora los canales de
                      pagos tradicionales cuentan con la tecnología de
                      PagoDirecto. Una transacción en menos de 3 seg gracias a
                      nuestra tecnología Bluetooth.
                    </p>
                  </div>
                </div>
                <div
                  className="data_block"
                  data-aos="fade-left"
                  data-aos-duration="1500"
                >
                  <div className="icon">
                    <img src={img4} alt="estacionamiento" style={{height: '130px'}}/>
                  </div>
                  <div className="text">
                    <h4>Parquímetros</h4>
                    <p>
                      Parquímetros digitales para las calles de nuestras
                      ciudades brindando una solución escalable y de pago
                      automatizado a través de teléfonos y puntos de ventas
                      autorizados.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="feature_img"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="100"
              >
                <img src={img5} alt="telefono"  />
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Main;
