import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Bredcrumb from "../Bredcrumb/Main";
import BGImg from "../../assets/images/bread_crumb_bg.png";
import BGImg1 from "../../assets/images/bread_crumb_bg_one.png";
import BGImg2 from "../../assets/images/bread_crumb_bg_two.png";

const Main = ({ brdcum }) => {
  return (
    <>
      <section
        className="contact_page_section"
        id="contact"
        style={{ marginTop: "300px", marginBottom: "10px" }}
      >
        <div className="container">
          <div className="contact_inner">
            <div className="contact_form">
              <div className="section_title">
                <h2>
                  <span>Déjanos tu mensaje</span>
                </h2>
                <p></p>
              </div>
              <form action="">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Nombre"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    placeholder="Email"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Nombre de la empresa"
                    className="form-control"
                  />
                </div>
                {/* <div className="form-group">
                  <select className="form-control">
                    <option value="">Country</option>
                  </select>
                </div> */}
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Numero"
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <textarea
                    className="form-control"
                    placeholder="Mensaje"
                  ></textarea>
                </div>

                <div className="form-group mb-0">
                  <button type="submit" className="btn puprple_btn">
                    Enviar mensaje
                  </button>
                </div>
              </form>
            </div>
            <div className="contact_info">
              <div className="icon"></div>
              <div className="section_title step_text_work">
                <h2>¿Tienes alguna duda?</h2>
                <p>
                  Si tienes alguna pregunta sobre nuestro producto, servicio,
                  pago o empresa, visite nuestra página de preguntas frecuentes
                </p>
              </div>
              <Link
                to="/faq"
                className="btn puprple_btn"
                style={{ width: "280px" }}
              >
                Preguntas frecuentes
              </Link>
              <ul className="contact_info_list">
                <li>
                  <div className="img">
                    <img src="assets/images/mail_icon.png" alt="image" />
                  </div>
                  <div className="text">
                    <span>Email </span>
                    <Link to="#">Ventas@pagodirecto.com</Link>
                  </div>
                </li>
                <li>
                  <div className="img">
                    <img src="assets/images/call_icon.png" alt="image" />
                  </div>
                  <div className="text">
                    <span>Teléfono</span>
                    <Link to="#">+58 412 712 7604</Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="row_am map_section">
            <div className="container">
                <div className="map_inner">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387190.2799160891!2d-74.25987584510595!3d40.69767006338158!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1664399300741!5m2!1sen!2sin" width="100%" height="510" style={{border:"0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </section> */}
    </>
  );
};

export default Main;
