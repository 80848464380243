import React, { useState } from "react";
import { Link } from "react-router-dom";
import img from "../../../assets/images/entra- estacionamiento.svg";
import img1 from "../../../assets/images/paga-estacionamiento.svg";
import img2 from "../../../assets/images/sal-estacionamiento.svg";

import servicios1 from "../../../assets/images/ingresadatos-servicios.svg";
import servicios2 from "../../../assets/images/verifica-servicios.svg";
import servicios3 from "../../../assets/images/paga-servicios.svg";

import parquimetros1 from "../../../assets/images/ubica-parking.svg";
import parquimetros2 from "../../../assets/images/Gestiona-parking.svg";
import parquimetros3 from "../../../assets/images/sal-parking.svg";

import peajes1 from "../../../assets/images/recarga-peaje.svg";
import peajes2 from "../../../assets/images/conecta-peaje.svg";
import peajes3 from "../../../assets/images/sal-peaje.svg";

import video from "../../../assets/images/play_icon.png";
import bannerEstacionamiento from "../../../assets/images/banner-estacionamiento.svg";
import bannerServicios from "../../../assets/images/banner-servicios.svg";
import bannerPeaje from "../../../assets/images/banner-peaje.svg";
import bannerParquimetro from "../../../assets/images/banner-parquimetro.svg";
import bannerEstacionamientoMovil from "../../../assets/images/banner-estacionamiento-movil.svg";
import bannerServiciosMovil from "../../../assets/images/banner-servicios-movil.svg";
import bannerPeajeMovil from "../../../assets/images/banner-peaje-movil.svg";
import bannerParquimetroMovil from "../../../assets/images/banner-parquimetro-movil.svg";

import estIcon from "../../../assets/images/icon-est.svg";
import servIcon from "../../../assets/images/icon-serv.svg";
import peaIcon from "../../../assets/images/icon-pea.svg";
import parIcon from "../../../assets/images/icon-par.svg";

import OwlCarousel from "react-owl-carousel";

const Main = ({ dark }) => {
  const [ytShow, setytShow] = useState(false);

  const options = {
    first: "first",
    second: "second",
    third: "third",
    fourth: "fourth",
  };

  const Item = ({ onClick, index }) => {
    return <div onClick={() => onClick(index)}>Item {index}</div>;
  };

  const handleClick = (event) => {
    const item = event.target.closest(".item");
    const index = item.getAttribute("index");
    setSelectOptions(index);
  };

  const [selectOptions, setSelectOptions] = useState(options.first);

  const company_slider = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: false,
    smartSpeed: 1500,
    dots: true,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <>
      <section className="row_am how_it_works" id="how_it_work">
        <div className="container">
          <div className={`how_it_inner ${dark && "dark"}`}>
            <div
              className="section_title "
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="300"
            >
              <h2 className="sub-title-mb">
                <span>¿Cómo usar?</span>{" "}
              </h2>
              <p>Selecciona una función para visualizar el paso a paso.</p>

              <div
                className="selected_option_list"
                style={{ marginBottom: "50px" }}
              >
                <img
                  src={estIcon}
                  alt="estacionamiento"
                  style={{ height: "125px" }}
                  onClick={() => setSelectOptions(options.first)}
                />
                <img
                  src={servIcon}
                  alt="servicios"
                  style={{ height: "125px" }}
                  onClick={() => setSelectOptions(options.second)}
                />
                <img
                  src={peaIcon}
                  alt="peajes"
                  style={{ height: "125px" }}
                  onClick={() => setSelectOptions(options.third)}
                />
                <img
                  src={parIcon}
                  alt="estacionamiento"
                  style={{ height: "125px" }}
                  onClick={() => setSelectOptions(options.fourth)}
                />
              </div>

              <OwlCarousel
                id="company_slider"
                {...company_slider}
                className="owl-carousel owl-theme owl-loaded owl-drag selected_option_carrousel"
              >
                <div
                  className="item"
                  onClick={handleClick}
                  index={options.first}
                >
                  <div className="logo">
                    <img src={estIcon} alt="estacionamiento" />
                  </div>
                </div>
                <div
                  className="item"
                  onClick={handleClick}
                  index={options.second}
                >
                  <div className="logo">
                    <img src={servIcon} alt="servicios" />
                  </div>
                </div>
                <div
                  className="item"
                  onClick={handleClick}
                  index={options.third}
                >
                  <div className="logo">
                    <img src={peaIcon} alt="peajes" />
                  </div>
                </div>
                <div
                  className="item"
                  index={options.fourth}
                  onClick={handleClick}
                >
                  <div className="logo">
                    <img src={parIcon} alt="estacionamiento" />
                  </div>
                </div>
              </OwlCarousel>
            </div>

            {selectOptions === options.first && (
              <div className="step_block">
                <ul>
                  <li>
                    <div
                      className="step_text step_text_work"
                      data-aos="fade-right"
                      data-aos-duration="1500"
                    >
                      <h3 style={{ color: "#CE4C73" }}>
                        <span>Entra</span>
                      </h3>

                      <p>
                        Levanta la barrera únicamente presionando Entrar desde tu
                        teléfono.{" "}
                      </p>
                    </div>
                    <div className="step_number_a"></div>
                    <div
                      className="step_img"
                      data-aos="fade-left"
                      data-aos-duration="1500"
                    >
                      <img src={img} alt="images" className="how_use_images " />
                    </div>
                  </li>
                  <li>
                    <div
                      className="step_text step_text_work"
                      data-aos="fade-left"
                      data-aos-duration="1500"
                    >
                      <h3 style={{ color: "#CE4C73" }}>Paga</h3>
                      <p>Paga tu ticket digital sin pasar por taquilla.</p>
                    </div>
                    <div className="step_number_b"></div>
                    <div
                      className="step_img"
                      data-aos="fade-right"
                      data-aos-duration="1500"
                    >
                      <img src={img1} alt="imag1" className="how_use_images " />
                    </div>
                  </li>
                  <li>
                    <div
                      className="step_text step_text_work"
                      data-aos="fade-right"
                      data-aos-duration="1500"
                    >
                      <h3 style={{ color: "#CE4C73" }}>Sal</h3>

                      <p>
                        Ahorra tiempo, sal solo tocando el botón Salir en tu
                        dispositivo móvil.
                      </p>
                    </div>
                    <div className="step_number_c"></div>
                    <div
                      className="step_img"
                      data-aos="fade-left"
                      data-aos-duration="1500"
                    >
                      <img
                        src={img2}
                        alt="imag2"
                        className="how_use_images last_image"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            )}

            {selectOptions === options.second && (
              <div className="step_block">
                <ul>
                  <li>
                    <div
                      className="step_text step_text_work"
                      data-aos="fade-right"
                      data-aos-duration="1500"
                    >
                      <h3 style={{ color: "#CE4C73" }}>
                        <span>Ingresa tus datos</span>
                      </h3>

                      <p>
                        Según el servicios deberás ingresar tu número
                        telefónico, de contrato o cédula.
                      </p>
                    </div>
                    <div className="step_number_a"></div>
                    <div
                      className="step_img"
                      data-aos="fade-left"
                      data-aos-duration="1500"
                    >
                      <img
                        src={servicios1}
                        alt="images"
                        className="how_use_images "
                      />
                    </div>
                  </li>
                  <li>
                    <div
                      className="step_text step_text_work"
                      data-aos="fade-left"
                      data-aos-duration="1500"
                    >
                      <h3 style={{ color: "#CE4C73" }}>
                        Verifica tu información
                      </h3>
                      <p>Ingresa el monto o elige el paquete a pagar.</p>
                    </div>
                    <div className="step_number_b"></div>
                    <div
                      className="step_img"
                      data-aos="fade-right"
                      data-aos-duration="1500"
                    >
                      <img
                        src={servicios2}
                        alt="imag1"
                        className="how_use_images "
                      />
                    </div>
                  </li>
                  <li>
                    <div
                      className="step_text step_text_work"
                      data-aos="fade-right"
                      data-aos-duration="1500"
                    >
                      <h3 style={{ color: "#CE4C73" }}>Paga</h3>

                      <p>
                        Realiza el pago de todos tus servicios en solo segundos.
                      </p>
                    </div>
                    <div className="step_number_c"></div>
                    <div
                      className="step_img"
                      data-aos="fade-left"
                      data-aos-duration="1500"
                    >
                      <img
                        src={servicios3}
                        alt="imag2"
                        className="how_use_images last_image"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            )}

            {selectOptions === options.third && (
              <div className="step_block">
                <ul>
                  <li>
                    <div
                      className="step_text step_text_work"
                      data-aos="fade-right"
                      data-aos-duration="1500"
                    >
                      <h3 style={{ color: "#CE4C73" }}>
                        <span>Recarga tu wallet</span>
                      </h3>

                      <p>
                        Para usar esta función es necesario que tengas saldo
                        disponible en tu wallet.
                      </p>
                    </div>
                    <div className="step_number_a"></div>
                    <div
                      className="step_img"
                      data-aos="fade-left"
                      data-aos-duration="1500"
                    >
                      <img
                        src={peajes1}
                        alt="images"
                        className="how_use_images "
                      />
                    </div>
                  </li>
                  <li>
                    <div
                      className="step_text step_text_work"
                      data-aos="fade-left"
                      data-aos-duration="1500"
                    >
                      <h3 style={{ color: "#CE4C73" }}>Acceso directo</h3>
                      <p>
                        Con solo tocar un botón podrás levantar la barrera del
                        peaje y continuar tu viaje.
                      </p>
                    </div>
                    <div className="step_number_b"></div>
                    <div
                      className="step_img"
                      data-aos="fade-right"
                      data-aos-duration="1500"
                    >
                      <img
                        src={peajes2}
                        alt="imag1"
                        className=" how_use_images"
                      />
                    </div>
                  </li>
                  <li>
                    <div
                      className="step_text step_text_work"
                      data-aos="fade-right"
                      data-aos-duration="1500"
                    >
                      <h3 style={{ color: "#CE4C73" }}>Listo</h3>

                      <p>
                        El pago se realizará automáticamente, descontado el
                        dinero de tu wallet.
                      </p>
                    </div>
                    <div className="step_number_c"></div>
                    <div
                      className="step_img"
                      data-aos="fade-left"
                      data-aos-duration="1500"
                    >
                      <img
                        src={peajes3}
                        alt="imag2"
                        className="last_image how_use_images"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            )}

            {selectOptions === options.fourth && (
              <div className="step_block">
                <ul>
                  <li>
                    <div
                      className="step_text step_text_work"
                      data-aos="fade-right"
                      data-aos-duration="1500"
                    >
                      <h3 style={{ color: "#CE4C73" }}>
                        <span>Ubica tu parquímetro</span>
                      </h3>

                      <p>
                        Podrás seleccionar el parquímetro mediante
                        geolocalización o escaneando el código QR que está en la
                        señalización.
                      </p>
                    </div>
                    <div className="step_number_a"></div>
                    <div
                      className="step_img"
                      data-aos="fade-left"
                      data-aos-duration="1500"
                    >
                      <img
                        src={parquimetros1}
                        alt="images"
                        className="how_use_images "
                      />
                    </div>
                  </li>
                  <li>
                    <div
                      className="step_text step_text_work"
                      data-aos="fade-left"
                      data-aos-duration="1500"
                    >
                      <h3 style={{ color: "#CE4C73" }}>Configura tu tiempo</h3>
                      <p>
                        Gestiona el tiempo que permanecerás estacionado en el
                        lugar.
                      </p>
                    </div>
                    <div className="step_number_b"></div>
                    <div
                      className="step_img"
                      data-aos="fade-right"
                      data-aos-duration="1500"
                    >
                      <img
                        src={parquimetros2}
                        alt="imag1"
                        className="how_use_images "
                      />
                    </div>
                  </li>
                  <li>
                    <div
                      className="step_text step_text_work"
                      data-aos="fade-right"
                      data-aos-duration="1500"
                    >
                      <h3 style={{ color: "#CE4C73" }}>Realiza el pago</h3>

                      <p>
                        El pago podrás realizarlo directamente desde la app. En
                        caso de que el tiempo expire, podrás extenderlo y pagar
                        nuevamente.
                      </p>
                    </div>
                    <div className="step_number_c"></div>
                    <div
                      className="step_img"
                      data-aos="fade-left"
                      data-aos-duration="1500"
                    >
                      <img
                        src={parquimetros3}
                        alt="imag2"
                        className="how_use_images last_image"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </div>

          {dark ? (
            <div
              className="yt_video"
              style={{ display: "none" }}
              data-aos="fade-in"
              data-aos-duration="1500"
            >
              <div className="thumbnil">
                <span className="banner_shape1">
                  {" "}
                  <img src="assets/images/banner-shape1.png" alt="image" />{" "}
                </span>
                <span className="banner_shape2">
                  {" "}
                  <img src="assets/images/banner-shape2.png" alt="image" />{" "}
                </span>
                <span className="banner_shape3">
                  {" "}
                  <img src="assets/images/banner-shape3.png" alt="image" />{" "}
                </span>
                <img src="assets/images/yt_thumb.png" alt="image" />
                <Link
                  to="#"
                  className="popup-youtube play-button"
                  data-url="#"
                  onClick={() => setytShow(true)}
                  data-toggle="modal"
                  data-target="#myModal"
                  title="XJj2PbenIsU"
                >
                  <span className="play_btn">
                    <img src={video} alt="image" />
                    <div className="waves-block">
                      <div className="waves wave-1"></div>
                      <div className="waves wave-2"></div>
                      <div className="waves wave-3"></div>
                    </div>
                  </span>
                  Let’s see virtually how it works
                  <span>Watch video</span>
                </Link>
              </div>
            </div>
          ) : (
            <div
              className="yt_video"
              data-aos="fade-in"
              data-aos-duration="1500"
            >
              <div className="thumbnil">
                {options.first === selectOptions && (
                  <>
                    <img
                      className="banner-image-how-use"
                      src={bannerEstacionamiento}
                      style={{ borderRadius: "20px" }}
                      alt="images"
                    />
                    <img
                      className="banner-image-how-use-movil"
                      src={bannerEstacionamientoMovil}
                      style={{ borderRadius: "20px" }}
                      alt="images"
                    />
                  </>
                )}
                {options.second === selectOptions && (
                  <>
                    <img
                      className="banner-image-how-use"
                      src={bannerServicios}
                      style={{ borderRadius: "20px" }}
                      alt="images"
                    />

                    <img
                      className="banner-image-how-use-movil"
                      src={bannerServiciosMovil}
                      style={{ borderRadius: "20px" }}
                      alt="images"
                    />
                  </>
                )}
                {options.third === selectOptions && (
                  <>
                    <img
                      className="banner-image-how-use"
                      src={bannerPeaje}
                      style={{ borderRadius: "20px" }}
                      alt="images"
                    />
                    <img
                      className="banner-image-how-use-movil"
                      src={bannerPeajeMovil}
                      style={{ borderRadius: "20px" }}
                      alt="images"
                    />
                  </>
                )}
                {options.fourth === selectOptions && (
                  <>
                    <img
                      className="banner-image-how-use"
                      src={bannerParquimetro}
                      style={{ borderRadius: "20px" }}
                      alt="images"
                    />
                    <img
                      className="banner-image-how-use-movil"
                      src={bannerParquimetroMovil}
                      style={{ borderRadius: "20px" }}
                      alt="images"
                    />
                  </>
                )}

                <Link
                  to="#"
                  className="popup-youtube play-button"
                  data-url="#"
                  onClick={() => setytShow(true)}
                  data-toggle="modal"
                  data-target="#myModal"
                  title="XJj2PbenIsU"
                >
                  <span className="play_btn">
                    <img src={video} alt="image" />
                    <div className="waves-block">
                      <div className="waves wave-1"></div>
                      <div className="waves wave-2"></div>
                      <div className="waves wave-3"></div>
                    </div>
                  </span>
                </Link>
              </div>
            </div>
          )}
        </div>
      </section>
      {ytShow && (
        <>
          <div
            className="modal fade youtube-video show"
            id="myModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
            aria-modal="true"
            style={{ paddingRight: "17px", display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <button
                  id="close-video"
                  type="button"
                  className="button btn btn-default text-right"
                  data-dismiss="modal"
                >
                  <i
                    className="icofont-close-line-circled"
                    onClick={() => setytShow(false)}
                  ></i>
                </button>
                <div className="modal-body">
                  <div id="video-container" className="video-container">
                    {options.first === selectOptions && (
                      <iframe
                        id="youtubevideo"
                        src={"https://www.youtube.com/embed/Xb4gx3nZw9Y"}
                        width="500"
                        height="360"
                        frameborder="0"
                        allowfullscreen=""
                      ></iframe>
                    )}
                    {options.second === selectOptions && (
                      <iframe
                        id="youtubevideo"
                        src={"https://www.youtube.com/embed/_6qc8wMaMbs"}
                        width="500"
                        height="360"
                        frameborder="0"
                        allowfullscreen=""
                      ></iframe>
                    )}
                    {options.third === selectOptions && (
                      <iframe
                        id="youtubevideo"
                        src={"https://www.youtube.com/embed/nr0gMYnmARE"}
                        width="500"
                        height="360"
                        frameborder="0"
                        allowfullscreen=""
                      ></iframe>
                    )}
                    {options.fourth === selectOptions && (
                      <iframe
                        id="youtubevideo"
                        src={"https://www.youtube.com/embed/g17CWJ3l4V8"}
                        width="500"
                        height="360"
                        frameborder="0"
                        allowfullscreen=""
                      ></iframe>
                    )}

                    {/* <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/Xb4gx3nZw9Y"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe> */}
                  </div>
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>
          <div className="purple_backdrop"></div>
        </>
      )}
    </>
  );
};

export default Main;
